import * as api from '../../api/endpoints';

export const SESSION_REQUEST = 'snowmap/App/SESSION_REQUEST';
export const sessionRequest = () => ({
  type: SESSION_REQUEST
});

export const SESSION_RESPONSE = 'snowmap/App/SESSION_RESPONSE';
export const sessionResponse = session => ({
  type: SESSION_RESPONSE,
  session
});

export const SESSION_ERROR = 'snowmap/App/SESSION_ERROR';
export const sessionError = error => ({
  type: SESSION_ERROR,
  error
});

export const fetchConfig = () => async dispatch => {
  try {
    dispatch(sessionRequest());
    const res = await api.getSession();
    dispatch(sessionResponse(res));
  } catch (err) {
    dispatch(sessionError(err));
  }
};
