import { set } from 'lodash';
import * as api from '../../api/endpoints';

export const SITE_REQUEST = 'snowmap/siteDetails/SITE_REQUEST';
export const siteRequest = () => ({
  type: SITE_REQUEST
});

export const SITE_RESPONSE = 'snowmap/siteDetails/SITE_RESPONSE';
export const siteResponse = response => ({
  type: SITE_RESPONSE,
  response
});

export const SITE_ERROR = 'snowmap/siteDetails/SITE_ERROR';
export const siteError = error => ({
  type: SITE_ERROR,
  error
});

export const getSite = siteId => async dispatch => {
  try {
    dispatch(siteRequest());
    const res = await api.getSiteDetails(siteId);
    set(res, 'skipNotification', true);
    dispatch(siteResponse(res));
  } catch (err) {
    dispatch(siteError(err));
  }
};

export const CURRENT_CONDITIONS_REQUEST = 'snowmap/siteDetails/CURRENT_CONDITIONS_REQUEST';
export const currentConditionsRequest = () => ({
  type: CURRENT_CONDITIONS_REQUEST
});

export const CURRENT_CONDITIONS_RESPONSE = 'snowmap/siteDetails/CURRENT_CONDITIONS_RESPONSE';
export const currentConditionsResponse = response => ({
  type: CURRENT_CONDITIONS_RESPONSE,
  response
});

export const CURRENT_CONDITIONS_ERROR = 'snowmap/siteDetails/CURRENT_CONDITIONS_ERROR';
export const currentConditionsError = error => ({
  type: CURRENT_CONDITIONS_ERROR,
  error
});

export const getCurrentConditions = () => async (dispatch, getState) => {
  try {
    dispatch(currentConditionsRequest());
    const { aerisClientId, aerisClientSecret } = getState().app;
    const { lat, lng } = getState().siteDetails.site;
    const conditionsRes = await api.getAerisConditions(lat, lng, {
      format: 'json',
      filter: '1min',
      plimit: '1',
      client_id: aerisClientId,
      client_secret: aerisClientSecret
    });
    const phrasesRes = await api.getAerisPhrases(lat, lng, {
      client_id: aerisClientId,
      client_secret: aerisClientSecret
    });
    const forecastsRes = await api.getAerisForecasts(lat, lng, {
      filter: 'mdnt2mdnt',
      from: 'today',
      to: '+3days',
      client_id: aerisClientId,
      client_secret: aerisClientSecret
    });
    const observationsRes = await api.getAerisObservations(lat, lng, {
      filter: 'allstations',
      client_id: aerisClientId,
      client_secret: aerisClientSecret
    });
    dispatch(
      currentConditionsResponse({
        skipNotification: true,
        conditionsRes,
        phrasesRes,
        forecastsRes,
        observationsRes
      })
    );
  } catch (err) {
    dispatch(currentConditionsError(err));
  }
};

export const componentMount = siteId => async dispatch => {
  await dispatch(getSite(siteId));
  dispatch(getCurrentConditions());
};

export const COMPONENT_UNMOUNT = 'snowmap/siteDetails/COMPONENT_UNMOUNT';
export const componentUnmount = () => ({
  type: COMPONENT_UNMOUNT
});
