import styled, { css, keyframes } from 'styled-components';

const rotate360 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const MapOverlayButton = styled.button`
  height: 38px;
  background-color: #555;
  border: none;
  border-radius: 4px;
  padding: 7px;
  cursor: pointer;

  &:hover {
    background-color: #444;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #444;
  }

  ${props =>
    props.animate &&
    css`
      svg {
        animation: ${rotate360} 0.75s linear infinite;
      }
    `}
`;

export default MapOverlayButton;
