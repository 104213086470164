import { isNil, omitBy } from 'lodash';

function parseJSON(response) {
  return response.status !== 204 && response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }
  const error = new Error();
  error.status = response.status;
  error.statusText = response.statusText;
  return response
    .json()
    .then(body => {
      error.body = body;
    })
    .catch(() => {}) // Catch body parsing errors and continue
    .then(() => {
      throw error;
    });
}

function getHeaders() {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  return headers;
}

function request(url, options, parserResponse) {
  const cleanOptions = omitBy(options, isNil);
  const fetchOptions = {
    ...cleanOptions,
    credentials: 'same-origin'
  };
  return fetch(url, fetchOptions)
    .then(checkStatus)
    .then(parserResponse || parseJSON);
}

export function post(url, body, customHeaders) {
  return request(url, {
    method: 'POST',
    headers: customHeaders || getHeaders(),
    body
  });
}

function encodeQueryData(data) {
  const ret = [];
  Object.keys(data).forEach(key => {
    ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`);
  });
  return ret.join('&');
}

export function get(url, params, headers, parserResponse) {
  let cleanParams = omitBy(params, isNil);
  cleanParams = omitBy(cleanParams, param => param === '');
  const urlParams = encodeQueryData(cleanParams);
  const urlStr = urlParams ? `${url}?${urlParams}` : `${url}`;
  return request(
    urlStr,
    {
      method: 'GET',
      headers: headers || getHeaders()
    },
    parserResponse
  );
}

export function remove(url) {
  return request(`${url}`, {
    method: 'DELETE',
    credentials: 'include',
    headers: getHeaders()
  });
}

export function put(url, body) {
  const cleanBody = omitBy(body, isNil);
  return request(url, {
    method: 'PUT',
    credentials: 'include',
    headers: getHeaders(),
    body: JSON.stringify(cleanBody)
  });
}
