import React from 'react';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { Link } from '@kbs/kbsforce-components';
import { NOT_AVAILABLE, TABLE_DATETIME_FORMAT } from './constants';
import { getNetSuiteCaseURL } from '.';

export const RECENT_CASES_COLUMNS = [
  {
    header: 'Case #',
    accessorKey: 'caseNumber',
    cell: ({ row }) => (
      <Link href={getNetSuiteCaseURL(row.original.srcId)} target="_blank" rel="noreferrer">
        {row.original.caseNumber}
      </Link>
    ),
    enableSorting: false
  },
  {
    header: 'Vendor ID',
    accessorKey: 'vendorId',
    cell: info => info.getValue(),
    enableSorting: false,
    size: 88
  },
  {
    header: 'Vendor',
    accessorKey: 'vendorName',
    cell: info => info.getValue(),
    enableSorting: false
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: info => info.getValue(),
    enableSorting: false
  },
  {
    id: 'incidentDate',
    header: () => (
      <>
        Incident
        <br />
        Date - Time
      </>
    ),
    accessorKey: 'incidentDate',
    cell: ({ row, table }) =>
      isNil(row.original.incidentDate)
        ? NOT_AVAILABLE
        : moment
            .tz(row.original.incidentDate, table.options.meta.siteTimeZone)
            .format(TABLE_DATETIME_FORMAT),
    enableSorting: false,
    size: 120
  }
];

export const RECENT_VENDOR_PUNCHES_COLUMNS = [
  {
    header: 'Vendor ID',
    accessorKey: 'vendorId',
    cell: info => info.getValue(),
    enableSorting: false,
    size: 88
  },
  {
    header: 'Vendor',
    accessorKey: 'vendorName',
    cell: info => info.getValue(),
    enableSorting: false
  },
  {
    id: 'punchedAt',
    header: () => (
      <>
        Punch In
        <br />
        Date - Time
      </>
    ),
    accessorKey: 'punchedAt',
    cell: ({ row, table }) =>
      isNil(row.original.punchedAt)
        ? NOT_AVAILABLE
        : moment
            .tz(row.original.punchedAt, table.options.meta.siteTimeZone)
            .format(TABLE_DATETIME_FORMAT),
    enableSorting: false,
    size: 120
  }
];
