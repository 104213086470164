import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../assets/mapboxPopup.css';
import styled, { useTheme } from 'styled-components';
import { get, isEqual, isNil } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Flex, LoadingSpinner } from '@kbs/kbsforce-components';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax, import/no-unresolved
import {
  ACTIVE,
  AERISWEATHER,
  BOTTOM_OVERLAY_HEIGHT,
  DEFAULT_CENTER,
  DEFAULT_ICON_SIZE,
  DEFAULT_ZOOM,
  END_MINUTES,
  FRAME_COUNT,
  ONE_HUNDRED,
  ONE_THOUSAND,
  PATHS_WITH_AERIS_LAYERS,
  SERVICE_MISSED,
  SERVICE_PERFORMED,
  ESTIMATED_SNOW_DEPTH_PATH,
  START_MINUTES,
  TOP_OVERLAY_HEIGHT,
  USA,
  SNOW_PATH,
  ZERO,
  SERVICE_CONFIRMED,
  INACTIVE
} from '../utils/constants';
import {
  createPopupHTML,
  getAerisTiles,
  getClusterPopupSecondLine,
  getMarkersAsGeoJSON,
  getPopupOffset
} from '../utils';
import siteBlue from '../assets/siteMarkers/site-blue.png';
import siteCyan from '../assets/siteMarkers/site-cyan.png';
import siteGrey from '../assets/siteMarkers/site-grey.png';
import siteRed from '../assets/siteMarkers/site-red.png';
import siteWhite from '../assets/siteMarkers/site-white.png';
import cluster1Blue from '../assets/siteMarkers/cluster-1-blue.png';
import cluster1Cyan from '../assets/siteMarkers/cluster-1-cyan.png';
import cluster1Grey from '../assets/siteMarkers/cluster-1-grey.png';
import cluster1Red from '../assets/siteMarkers/cluster-1-red.png';
import cluster1White from '../assets/siteMarkers/cluster-1-white.png';
import cluster2Blue from '../assets/siteMarkers/cluster-2-blue.png';
import cluster2Cyan from '../assets/siteMarkers/cluster-2-cyan.png';
import cluster2Grey from '../assets/siteMarkers/cluster-2-grey.png';
import cluster2Red from '../assets/siteMarkers/cluster-2-red.png';
import cluster2White from '../assets/siteMarkers/cluster-2-white.png';
import cluster3Blue from '../assets/siteMarkers/cluster-3-blue.png';
import cluster3Cyan from '../assets/siteMarkers/cluster-3-cyan.png';
import cluster3Grey from '../assets/siteMarkers/cluster-3-grey.png';
import cluster3Red from '../assets/siteMarkers/cluster-3-red.png';
import cluster3White from '../assets/siteMarkers/cluster-3-white.png';

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #555555d9;
  padding: 8px;
  color: ${props => props.theme.colors.neutral0};
`;

const PADDING_OPTIONS = {
  top: TOP_OVERLAY_HEIGHT + Math.ceil(DEFAULT_ICON_SIZE / 2),
  left: DEFAULT_ICON_SIZE / 2,
  bottom: BOTTOM_OVERLAY_HEIGHT + Math.ceil(DEFAULT_ICON_SIZE / 2),
  right: DEFAULT_ICON_SIZE / 2
};

const createSitePopup = async (e, map, location, onGetSiteConditions, popup) => {
  // set the map cursor as pointer (DOES NOT WORK)
  // eslint-disable-next-line no-param-reassign
  map.current.getCanvas().style.cursor = 'pointer';
  // get the site coordinates
  const coordinates = e.features[0].geometry.coordinates.slice();
  // get the site id
  const siteId = e.features[0].properties.id;
  // get the site name
  const siteName = e.features[0].properties.name;
  // get the weather condition from the feature state
  // check this https://docs.mapbox.com/mapbox-gl-js/api/map/#map#setfeaturestate
  // if you don't know what is the feature state
  const siteWeather = map.current.getFeatureState({ source: 'sites', id: siteId });
  let secondLine = '';
  // the second line (aka the weather in that site) must be rendered only if:
  // - the path is one of the paths that render an aeris layer (i.e not the sites only one)
  // - the site is not inactive
  if (
    PATHS_WITH_AERIS_LAYERS.includes(location.pathname) &&
    e.features[0].properties.status !== INACTIVE
  ) {
    // if the weather condition is not set, fetch it
    if (isNil(get(siteWeather, `[${location.pathname}]`))) {
      await onGetSiteConditions(e.features[0].properties, location.pathname);
      secondLine = get(
        map.current.getFeatureState({ source: 'sites', id: siteId }),
        `[${location.pathname}]`,
        ''
      );
    } else {
      // if it is set, assign it to the second line
      secondLine = get(siteWeather, `[${location.pathname}]`);
    }
  }
  // add the popup to the map
  popup
    .setLngLat(coordinates)
    .setOffset(getPopupOffset())
    .setHTML(createPopupHTML(siteName, secondLine))
    .addTo(map.current);
};

// timeout and interval ids for the animation
let timeoutId = null;
let intervalId = null;

/**
 * Gets the name of the image used in a cluster marker
 * @param {Number} number the number of the image name (1 for small, 2 for medium, 3 for large)
 * @returns an expression for the image name
 */
const getClusterImage = number => [
  'case',
  ['>', ['get', 'serviceMissed'], ZERO],
  `cluster-${number}-red`,
  ['>', ['get', 'active'], ZERO],
  `cluster-${number}-white`,
  ['>', ['get', 'serviceConfirmed'], ZERO],
  `cluster-${number}-blue`,
  ['==', ['get', 'servicePerformed'], ['get', 'point_count']],
  `cluster-${number}-cyan`,
  `cluster-${number}-grey`
];

const Mapbox = props => {
  const {
    id,
    accessToken,
    aerisClientId,
    aerisClientSecret,
    zoom,
    center,
    markers,
    loadingMarkers,
    onMarkerClick,
    autozoom,
    siteWeatherConditions,
    onGetSiteConditions,
    currentOffset,
    onCurrentOffsetChange,
    playingAnimation,
    onFinishedLoad,
    region
  } = props;

  const location = useLocation();
  const { colors } = useTheme();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [currentMarkers, setCurrentMarkers] = useState(getMarkersAsGeoJSON([]));
  const [currentSiteWeatherConditions, setCurrentSiteWeatherConditions] = useState([]);
  const [shouldDoAutozoom, setShouldDoAutozoom] = useState(true);
  // this flag is for checking if the map has already loaded
  const [loaded, setLoaded] = useState(false);
  // TODO: maybe a ref would be more appropriate
  const [popup] = useState(
    new mapboxgl.Popup({
      anchor: 'top-left',
      closeButton: false,
      closeOnClick: false
    })
  );
  // indicates if the animation can start playing
  const [canPlay, setCanPlay] = useState(false);
  const [frames, setFrames] = useState([]);

  useEffect(() => {
    if (autozoom) {
      setShouldDoAutozoom(true);
    }
  }, [autozoom]);

  // effect to create the map
  useEffect(() => {
    if (map.current || !accessToken) {
      return;
    }
    // create a new instance of the map
    map.current = new mapboxgl.Map({
      accessToken,
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v10',
      center,
      zoom
    });
    // all of this chunk will execute when the map loads
    map.current.on('load', async () => {
      // sites source
      map.current.addSource('sites', {
        type: 'geojson',
        data: markers,
        cluster: true,
        clusterMaxZoom: 14,
        clusterProperties: {
          servicePerformed: ['+', ['case', ['==', ['get', 'status'], SERVICE_PERFORMED], 1, 0]],
          serviceConfirmed: ['+', ['case', ['==', ['get', 'status'], SERVICE_CONFIRMED], 1, 0]],
          serviceMissed: ['+', ['case', ['==', ['get', 'status'], SERVICE_MISSED], 1, 0]],
          active: ['+', ['case', ['==', ['get', 'status'], ACTIVE], 1, 0]]
        }
      });
      // small cluster images
      await map.current.loadImage(cluster1Blue, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-1-blue', image);
      });
      await map.current.loadImage(cluster1Cyan, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-1-cyan', image);
      });
      await map.current.loadImage(cluster1Grey, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-1-grey', image);
      });
      await map.current.loadImage(cluster1Red, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-1-red', image);
      });
      await map.current.loadImage(cluster1White, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-1-white', image);
      });
      // medium cluster images
      await map.current.loadImage(cluster2Blue, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-2-blue', image);
      });
      await map.current.loadImage(cluster2Cyan, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-2-cyan', image);
      });
      await map.current.loadImage(cluster2Grey, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-2-grey', image);
      });
      await map.current.loadImage(cluster2Red, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-2-red', image);
      });
      await map.current.loadImage(cluster2White, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-2-white', image);
      });
      // big cluster images
      await map.current.loadImage(cluster3Blue, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-3-blue', image);
      });
      await map.current.loadImage(cluster3Cyan, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-3-cyan', image);
      });
      await map.current.loadImage(cluster3Grey, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-3-grey', image);
      });
      await map.current.loadImage(cluster3Red, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-3-red', image);
      });
      await map.current.loadImage(cluster3White, (error, image) => {
        if (error) throw error;
        map.current.addImage('cluster-3-white', image);
      });
      // marker images
      await map.current.loadImage(siteBlue, (error, image) => {
        if (error) throw error;
        map.current.addImage('site-blue', image);
      });
      await map.current.loadImage(siteCyan, (error, image) => {
        if (error) throw error;
        map.current.addImage('site-cyan', image);
      });
      await map.current.loadImage(siteGrey, (error, image) => {
        if (error) throw error;
        map.current.addImage('site-grey', image);
      });
      await map.current.loadImage(siteRed, (error, image) => {
        if (error) throw error;
        map.current.addImage('site-red', image);
      });
      await map.current.loadImage(siteWhite, (error, image) => {
        if (error) throw error;
        map.current.addImage('site-white', image);
      });
      // clusters layer
      map.current.addLayer({
        id: 'cluster-layer',
        type: 'symbol',
        source: 'sites',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': [
            'step',
            ['get', 'point_count'],
            getClusterImage(1),
            ONE_HUNDRED,
            getClusterImage(2),
            ONE_THOUSAND,
            getClusterImage(3)
          ],
          'icon-size': 0.25, // relative to the original icon size
          'icon-allow-overlap': true
        }
      });
      // cluster count layer
      map.current.addLayer({
        id: 'cluster-count-layer',
        type: 'symbol',
        source: 'sites',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count}',
          // 'text-font': ['Roboto'],
          'text-size': 14,
          'text-allow-overlap': true
        },
        paint: {
          'text-color': [
            'case',
            ['all', ['>', ['get', 'active'], ZERO], ['==', ['get', 'serviceMissed'], ZERO]],
            colors.neutral100,
            colors.neutral0
          ]
        }
      });
      // sites layer
      map.current.addLayer({
        id: 'site-layer',
        type: 'symbol',
        source: 'sites',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'status'], SERVICE_PERFORMED],
            'site-cyan',
            ['==', ['get', 'status'], SERVICE_MISSED],
            'site-red',
            ['==', ['get', 'status'], SERVICE_CONFIRMED],
            'site-blue',
            ['==', ['get', 'status'], ACTIVE],
            'site-white',
            'site-grey'
          ],
          'icon-size': 0.25 // relative to the original icon size
        }
      });

      const popup = new mapboxgl.Popup({
        anchor: 'top-left',
        closeButton: false,
        closeOnClick: false
      });

      // render a popup when hovering a cluster
      map.current.on('mouseenter', 'cluster-layer', e => {
        // copy coordinates array
        const coordinates = e.features[0].geometry.coordinates.slice();
        const { servicePerformed, serviceConfirmed, serviceMissed, active } =
          e.features[0].properties;
        const count = e.features[0].properties.point_count;
        // populate the popup and set its coordinates
        const secondLine = getClusterPopupSecondLine(
          servicePerformed,
          serviceConfirmed,
          serviceMissed,
          active,
          count
        );
        popup
          .setLngLat(coordinates)
          .setOffset(getPopupOffset(count))
          .setHTML(
            createPopupHTML(
              `${count} Sites`,
              secondLine,
              serviceMissed > ZERO,
              servicePerformed === count
            )
          )
          .addTo(map.current);
      });

      // remove a popup when unhovering a cluster
      map.current.on('mouseleave', 'cluster-layer', () => {
        popup.remove();
      });

      // zoom into a cluster when clicking it
      map.current.on('click', 'cluster-layer', e => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ['cluster-layer']
        });
        const clusterId = features[0].properties.cluster_id;
        map.current.getSource('sites').getClusterExpansionZoom(clusterId, (err, clusterZoom) => {
          if (err) return;
          map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: clusterZoom
          });
        });
      });

      // open the side panel (or whatever you have passed via props) when clicking on a site
      map.current.on('click', 'site-layer', e => {
        onMarkerClick(e.features[0].properties);
      });
      // set that the map has already finished loading
      // to avoid crashes if we try to add an aeris layer to a non-loaded map
      setLoaded(true);
      onFinishedLoad(true);
    });
  });

  // effect to reload the markers when they change
  useEffect(() => {
    if (map.current && map.current.getSource('sites') && !isEqual(markers, currentMarkers)) {
      setCurrentMarkers(markers);
      // update the source with the new markers
      map.current.getSource('sites').setData(markers);
      // fit the map to see all the sites
      if (shouldDoAutozoom) {
        const bounds = new mapboxgl.LngLatBounds();
        markers.features.forEach(feature => {
          bounds.extend(feature.geometry.coordinates);
        });
        if (!bounds.isEmpty()) {
          map.current.fitBounds(bounds, {
            padding: PADDING_OPTIONS,
            maxZoom: 10
          });
        }
        setShouldDoAutozoom(false);
      }
    }
  }, [markers]);

  // effect to store the weather conditions in the source data
  useEffect(() => {
    if (
      map.current &&
      map.current.getSource('sites') &&
      !isEqual(siteWeatherConditions, currentSiteWeatherConditions)
    ) {
      setCurrentSiteWeatherConditions(siteWeatherConditions);
      siteWeatherConditions.forEach(s => {
        map.current.setFeatureState({ source: 'sites', id: s.id }, { ...s });
      });
    }
  }, [siteWeatherConditions]);

  // effect to change the aeris layer when the path changes
  useEffect(() => {
    if (map.current && loaded) {
      // set that the animation can't start (until we download all the tiles)
      setCanPlay(false);
      // empty the frames array (because we are going to remove all animation layers)
      setFrames([]);
      // set the offset to 0 to start the animation from the beginning next time
      onCurrentOffsetChange(ZERO);
      // clear the animation timeout and interval to stop animating
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
      // remove all aeris layers
      map.current.getStyle().layers.forEach(layer => {
        if (layer.id.includes(AERISWEATHER)) {
          map.current.removeLayer(layer.id);
        }
      });
      // remove all aeris sources
      Object.keys(map.current.getStyle().sources).forEach(source => {
        if (source.includes(AERISWEATHER)) {
          map.current.removeSource(source);
        }
      });
      // if the path should render a layer (i.e, you are not in the "sites only" menu), add the source and layer
      if (PATHS_WITH_AERIS_LAYERS.includes(location.pathname)) {
        // if you are not in the weather radar, we must add one source and one layer only
        if (location.pathname !== SNOW_PATH) {
          // add the aeris source
          map.current.addSource(AERISWEATHER, {
            type: 'raster',
            tiles: getAerisTiles(aerisClientId, aerisClientSecret, location.pathname, region),
            tileSize: 256,
            attribution: '<a href="https://www.aerisweather.com/">AerisWeather</a>'
          });
          // add the aeris layer
          map.current.addLayer(
            {
              id: `${AERISWEATHER}-layer`,
              type: 'raster',
              source: AERISWEATHER,
              minzoom: 0,
              maxzoom: 22
            },
            // the 2nd argument is for putting the aeris layer below this other layer
            'cluster-layer'
          );
        }
        // if you are in the weather radar, we must add a lot of sources/layers to animate
        else {
          // the interval (in minutes) between layers
          const interval = (END_MINUTES - START_MINUTES) / FRAME_COUNT;
          // array for putting the new frames to be rendered
          const newFrames = [];
          // set up the animation frames and layers
          for (let i = 0; i < FRAME_COUNT; i += 1) {
            // the first layer should be opaque (because it will be the first one to show)
            const opacity = i === 0 ? 1 : 0;
            // time offset from the first layer
            const timeOffset = START_MINUTES + interval * i;
            // id for the source and layers that we are going to add
            const layerId = `${AERISWEATHER}-${timeOffset}`;
            // add a source (for one frame)
            map.current.addSource(layerId, {
              type: 'raster',
              tiles: getAerisTiles(
                aerisClientId,
                aerisClientSecret,
                location.pathname,
                region,
                `${timeOffset}min`
              ),
              tileSize: 256,
              attribution: '<a href="https://www.aerisweather.com/">AerisWeather</a>'
            });
            // add a layer (for one frame)
            map.current.addLayer({
              id: layerId,
              type: 'raster',
              source: layerId,
              minzoom: 0,
              maxzoom: 22,
              paint: {
                'raster-opacity': opacity,
                'raster-opacity-transition': {
                  duration: 0,
                  delay: 0
                }
              }
            });
            // put the id ih the newFrames array
            newFrames.push(layerId);
          }
          // store the new frames in the state
          setFrames(newFrames);

          // wait time determines how long to wait and allow frames to load before beginning animation
          const waitTime = 5000;
          timeoutId = setTimeout(() => {
            // set that the animation can start now
            setCanPlay(true);
          }, waitTime);
        }
      }
    }
  }, [location.pathname, loaded]);

  // effect to change the aeris layers when the region changes
  useEffect(() => {
    if (map.current && loaded && location.pathname === ESTIMATED_SNOW_DEPTH_PATH) {
      // remove all aeris layers
      map.current.getStyle().layers.forEach(layer => {
        if (layer.id.includes(AERISWEATHER)) {
          map.current.removeLayer(layer.id);
        }
      });
      // remove all aeris sources
      Object.keys(map.current.getStyle().sources).forEach(source => {
        if (source.includes(AERISWEATHER)) {
          map.current.removeSource(source);
        }
      });
      // add the aeris source
      map.current.addSource(AERISWEATHER, {
        type: 'raster',
        tiles: getAerisTiles(aerisClientId, aerisClientSecret, location.pathname, region),
        tileSize: 256,
        attribution: '<a href="https://www.aerisweather.com/">AerisWeather</a>'
      });
      // add the aeris layer
      map.current.addLayer({
        id: `${AERISWEATHER}-layer`,
        type: 'raster',
        source: AERISWEATHER,
        minzoom: 0,
        maxzoom: 22
      });
    }
  }, [region]);

  // effect to start/stop an animation
  useEffect(() => {
    // if playingAnimation, the user clicked play, so start animating
    if (canPlay && playingAnimation) {
      // step time determines the time in milliseconds each frame holds before advancing
      const stepTime = 1000;
      // current offset to show (i.e set its layer opacity to 1)
      let offset = currentOffset;
      // the offset previously shown (i.e the one to set its opacity to 0)
      let previousOffset = offset;
      // the interval for cycling between offsets
      intervalId = setInterval(() => {
        // update previous and current offsets
        previousOffset = offset;
        offset += 1;
        // if the current offset reached the frames length, go back to the first frame
        if (offset === frames.length) {
          offset = 0;
        }
        // change the offset in the parent component (for the player thing)
        onCurrentOffsetChange(offset);
        // change the opacity of the previous and current frame layers
        map.current.setPaintProperty(frames[previousOffset], 'raster-opacity', 0);
        map.current.setPaintProperty(frames[offset], 'raster-opacity', 1);
      }, stepTime);
    }
    // if !playingAnimation, then the user clicked pause, so clear the interval
    if (!playingAnimation) {
      clearInterval(intervalId);
    }
  }, [playingAnimation, canPlay, frames]);

  // effect to show a popup when hovering a site
  // i removed this code from the onload map event, because if you change the path
  // the function will still be linked to the old path
  useEffect(() => {
    if (map.current && loaded && map.current.getLayer('site-layer')) {
      // unregister the previous event listener to avoid showing more than one popup
      map.current.off('mouseenter', 'site-layer', e =>
        createSitePopup(e, map, location, onGetSiteConditions, popup)
      );
      // register a new event listener for rendering a new popup
      map.current.on('mouseenter', 'site-layer', e =>
        createSitePopup(e, map, location, onGetSiteConditions, popup)
      );

      // remove the popup when unhovering the site
      map.current.on('mouseleave', 'site-layer', () => {
        map.current.getCanvas().style.cursor = '';
        popup.remove();
      });
    }
  }, [location.pathname, loaded]);

  // effect to change zoom when props change
  useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom);
    }
  }, [zoom]);

  // effect to change center when props change
  useEffect(() => {
    if (map.current) {
      map.current.setCenter(center);
    }
  }, [center]);

  return (
    <Flex width="100%" height="100%">
      <MapContainer id={id} ref={mapContainer} />
      {loadingMarkers && (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </Flex>
  );
};

Mapbox.propTypes = {
  id: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  aerisClientId: PropTypes.string.isRequired,
  aerisClientSecret: PropTypes.string.isRequired,
  zoom: PropTypes.number,
  center: PropTypes.arrayOf(PropTypes.number),
  markers: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loadingMarkers: PropTypes.bool,
  onMarkerClick: PropTypes.func,
  autozoom: PropTypes.bool,
  siteWeatherConditions: PropTypes.arrayOf(PropTypes.object),
  onGetSiteConditions: PropTypes.func,
  currentOffset: PropTypes.number,
  onCurrentOffsetChange: PropTypes.func,
  playingAnimation: PropTypes.bool,
  onFinishedLoad: PropTypes.func,
  region: PropTypes.string
};

Mapbox.defaultProps = {
  zoom: DEFAULT_ZOOM,
  center: DEFAULT_CENTER,
  markers: {},
  loadingMarkers: false,
  onMarkerClick: () => {},
  autozoom: false,
  siteWeatherConditions: [],
  onGetSiteConditions: () => {},
  currentOffset: ZERO,
  onCurrentOffsetChange: () => {},
  playingAnimation: false,
  onFinishedLoad: () => {},
  region: USA
};

export default Mapbox;
