import React from 'react';
import {
  MapSiteIcon,
  SidebarItem,
  SnowCoverIcon,
  SnowPrecipitationIcon,
  TemperatureIcon,
  WeatherRadarIcon
} from '@kbs/kbsforce-components';
import { NavLink } from 'react-router-dom';
import {
  ESTIMATED_SNOW_DEPTH_PATH,
  SNOW_ACCUMULATION_PATH,
  TEMPERATURE_PATH,
  SNOW_PATH
} from '../utils/constants';

const Menu = () => (
  <>
    <SidebarItem as={NavLink} to="/" icon={<MapSiteIcon />} label="Sites Only" />
    <SidebarItem as={NavLink} to={SNOW_PATH} icon={<WeatherRadarIcon />} label="Snow" />
    <SidebarItem
      as={NavLink}
      to={ESTIMATED_SNOW_DEPTH_PATH}
      icon={<SnowCoverIcon />}
      label="Estimated Snow Depth"
    />
    <SidebarItem
      as={NavLink}
      to={SNOW_ACCUMULATION_PATH}
      icon={<SnowPrecipitationIcon />}
      label="Snow Accumulation"
    />
    <SidebarItem
      as={NavLink}
      to={TEMPERATURE_PATH}
      icon={<TemperatureIcon />}
      label="Temperature"
    />
  </>
);

export default Menu;
