import { isEqual, lowerCase, startCase } from 'lodash';
import { getAppliedFiltersNumber, isInsideIFrame, mapOptions } from '../../utils';
import {
  ACTIVE,
  INACTIVE,
  ONE,
  REGION_OPTIONS,
  ESTIMATED_SNOW_DEPTH_PATH,
  SNOW_ACCUMULATION_PATH,
  TEMPERATURE_PATH,
  SNOW_PATH,
  ZERO
} from '../../utils/constants';
import {
  FILTER_CHANGE,
  FILTER_CLEAR,
  FILTERS_ERROR,
  FILTERS_REQUEST,
  FILTERS_RESPONSE,
  SET_CUSTOMER,
  SITES_STATUS_REQUEST,
  SITES_STATUS_RESPONSE,
  SITES_STATUS_ERROR,
  STATUS_DESCRIPTIONS_REQUEST,
  STATUS_DESCRIPTIONS_RESPONSE,
  STATUS_DESCRIPTIONS_ERROR,
  SITE_WEATHER_CONDITIONS_REQUEST,
  SITE_WEATHER_CONDITIONS_RESPONSE,
  SITE_WEATHER_CONDITIONS_ERROR
} from './actions';

const defaultState = {
  initialLoad: true,
  shouldShowLoading: true,
  loadingFilters: false,
  loadingSitesStatus: false,
  loadingStatusDescriptions: false,
  loadingSiteWeatherCondiitons: false,
  error: null,
  customers: [],
  sites: [],
  sitesStatuses: [],
  siteGroups: [],
  states: [],
  cities: [],
  banners: [],
  hierarchies: [],
  subcontractors: [],
  customerOptions: [],
  siteOptions: [],
  siteGroupOptions: [],
  stateOptions: [],
  cityOptions: [],
  bannerOptions: [],
  hierarchyOptions: [],
  subcontractorOptions: [],
  serviceStatusOptions: [],
  siteWeatherConditions: [],
  filter: {
    region: REGION_OPTIONS[ZERO],
    customer: {
      label: 'All Customers',
      value: -ONE
    },
    site: {
      label: 'All Sites',
      value: -ONE
    },
    siteGroup: {
      label: 'All Site Groups',
      value: -ONE
    },
    state: {
      label: 'All States',
      value: -ONE
    },
    city: {
      label: 'All Cities',
      value: -ONE
    },
    banner: {
      label: 'All Banners',
      value: -ONE
    },
    hierarchy: {
      label: 'All Hierarchies',
      value: -ONE
    },
    subcontractor: {
      label: 'All Subcontractors',
      value: -ONE
    },
    serviceStatus: {
      label: 'All Statuses',
      value: -ONE
    }
  },
  appliedFilters: ZERO
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case FILTERS_REQUEST:
      return {
        ...state,
        loadingFilters: true,
        error: null
      };
    case FILTERS_RESPONSE:
      return {
        ...state,
        initialLoad: false,
        loadingFilters: false,
        ...((isEqual(defaultState.filter.customer, state.filter.customer) || state.initialLoad) && {
          customers: action.response.customers,
          customerOptions: mapOptions({
            items: action.response.customers,
            labelFn: item => item.name,
            valueKey: 'id',
            allLabel: 'All Customers',
            allValue: -ONE,
            sort: item => item.label
          })
        }),
        ...((isEqual(defaultState.filter.site, state.filter.site) || state.initialLoad) && {
          sites: action.response.sites
        }),
        ...((isEqual(defaultState.filter.state, state.filter.state) || state.initialLoad) && {
          states: action.response.states,
          stateOptions: mapOptions({
            items: action.response.states,
            labelFn: item => item,
            allLabel: 'All States',
            allValue: -ONE,
            sort: item => item.label
          })
        }),
        ...((isEqual(defaultState.filter.city, state.filter.city) || state.initialLoad) && {
          cities: action.response.cities,
          cityOptions: mapOptions({
            items: action.response.cities,
            labelFn: item => startCase(lowerCase(item)),
            valueFn: item => item,
            allLabel: 'All Cities',
            allValue: -ONE,
            sort: item => item.label
          })
        }),
        ...((isEqual(defaultState.filter.banner, state.filter.banner) || state.initialLoad) && {
          banners: action.response.banner,
          bannerOptions: mapOptions({
            items: action.response.banners,
            labelFn: item => item.name,
            valueKey: 'id',
            allLabel: 'All Banners',
            allValue: -ONE,
            sort: item => item.label
          })
        }),
        ...((isEqual(defaultState.filter.hierarchy, state.filter.hierarchy) ||
          state.initialLoad) && {
          hierarchies: action.response.managers,
          hierarchyOptions: mapOptions({
            items: action.response.managers,
            labelFn: item => `${item.firstName} ${item.lastName}`,
            valueKey: 'id',
            allLabel: 'All Hierarchies',
            allValue: -ONE,
            sort: item => item.label
          })
        }),
        ...((isEqual(defaultState.filter.subcontractor, state.filter.subcontractor) ||
          state.initialLoad) && {
          subcontractors: action.response.vendors,
          subcontractorOptions: mapOptions({
            items: action.response.vendors,
            labelFn: item => item.name,
            valueKey: 'id',
            allLabel: 'All Subcontractors',
            allValue: -ONE,
            sort: item => item.label
          })
        })
      };
    case FILTERS_ERROR:
      return {
        ...state,
        loadingFilters: false,
        error: action.error
      };
    case SET_CUSTOMER: {
      return {
        ...state,
        customerId: action.customerId
      };
    }
    case SITES_STATUS_REQUEST:
      return {
        ...state,
        loadingSitesStatus: true,
        error: null
      };
    case SITES_STATUS_RESPONSE: {
      const { sites } = action.response;
      return {
        ...state,
        shouldShowLoading: false,
        loadingSitesStatus: false,
        sitesStatuses: sites,
        siteOptions: mapOptions({
          items: sites,
          labelFn: item => item.siteName,
          valueKey: 'siteId',
          allLabel: 'All Sites',
          allValue: -ONE,
          sort: item => item.label
        })
      };
    }
    case SITES_STATUS_ERROR:
      return {
        ...state,
        loadingSitesStatus: false,
        error: action.error
      };
    case STATUS_DESCRIPTIONS_REQUEST:
      return {
        ...state,
        loadingStatusDescriptions: true,
        serviceStatusOptions: []
      };
    case STATUS_DESCRIPTIONS_RESPONSE:
      return {
        ...state,
        loadingStatusDescriptions: false,
        serviceStatusOptions: mapOptions({
          items: action.response,
          filter: item => isInsideIFrame() || item.status !== INACTIVE,
          labelFn: item => {
            switch (item.status) {
              case ACTIVE:
                return 'Awaiting Service';
              case INACTIVE:
                return 'Sites';
              default:
                return startCase(lowerCase(item.status));
            }
          },
          valueKey: 'status',
          allLabel: 'All Statuses',
          allValue: -ONE,
          extraKeys: ['description']
        })
      };
    case STATUS_DESCRIPTIONS_ERROR:
      return {
        ...state,
        loadingStatusDescriptions: false,
        error: action.error
      };
    case SITE_WEATHER_CONDITIONS_REQUEST:
      return {
        ...state,
        loadingSiteWeatherCondiitons: true
      };
    case SITE_WEATHER_CONDITIONS_RESPONSE: {
      const data = [SNOW_PATH, ESTIMATED_SNOW_DEPTH_PATH].includes(action.weather)
        ? action.response.response.ob
        : action.response.response[0].periods[0];
      const indexToReplace = state.siteWeatherConditions.findIndex(s => s.id === action.id);
      const newSiteWeatherConditions = [...state.siteWeatherConditions];
      if (indexToReplace !== -ONE) {
        newSiteWeatherConditions.splice(indexToReplace, ONE, {
          ...state.siteWeatherConditions[indexToReplace],
          ...([SNOW_PATH, ESTIMATED_SNOW_DEPTH_PATH].includes(action.weather) && {
            [SNOW_PATH]: '',
            [ESTIMATED_SNOW_DEPTH_PATH]: `${data.snowDepthIN || '0'} in`
          }),
          ...([SNOW_ACCUMULATION_PATH, TEMPERATURE_PATH].includes(action.weather) && {
            [SNOW_ACCUMULATION_PATH]: `${data.snowIN} in`,
            [TEMPERATURE_PATH]: `${data.tempF} ºF`
          })
        });
      } else {
        newSiteWeatherConditions.push({
          id: action.id,
          ...([SNOW_PATH, ESTIMATED_SNOW_DEPTH_PATH].includes(action.weather) && {
            [SNOW_PATH]: '',
            [ESTIMATED_SNOW_DEPTH_PATH]: `${data.snowDepthIN || '0'} in`
          }),
          ...([SNOW_ACCUMULATION_PATH, TEMPERATURE_PATH].includes(action.weather) && {
            [SNOW_ACCUMULATION_PATH]: `${data.snowIN} in`,
            [TEMPERATURE_PATH]: `${data.tempF} ºF`
          })
        });
      }
      return {
        ...state,
        loadingSiteWeatherConditions: false,
        siteWeatherConditions: newSiteWeatherConditions
      };
    }
    case SITE_WEATHER_CONDITIONS_ERROR:
    case FILTER_CHANGE: {
      const newFilter = {
        ...state.filter,
        site: defaultState.filter.site,
        [action.key]: action.value,
        ...(action.key === 'state' && { city: defaultState.filter.city })
      };
      return {
        ...state,
        ...(action.key !== 'site' && { shouldShowLoading: true }),
        filter: newFilter,
        appliedFilters: getAppliedFiltersNumber(newFilter, defaultState.filter)
      };
    }
    case FILTER_CLEAR:
      return {
        ...state,
        shouldShowLoading: true,
        filter: defaultState.filter,
        appliedFilters: defaultState.appliedFilters
      };
    default:
      return state;
  }
};

export default reducer;
