export const APP_NAME = 'KBS Snow Map';
export const PROD_URL = 'snowmap.kbsforce.com';

// moment formats
export const DATE_FORMAT = 'MM/DD/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const LONG_DATETIME_FORMAT = 'LLL';
export const TABLE_DATETIME_FORMAT = 'MMM DD, YYYY hh:mm A';

// layout
export const FOOTER_HEIGHT_PX = 40;
export const HEADER_HEIGHT_PX = 56;
export const DEFAULT_CONTAINER_PADDING = 16;
export const isIE11 = !!document.documentMode;
export const TOP_OVERLAY_HEIGHT = 32;
export const BOTTOM_OVERLAY_HEIGHT = 108;
export const TIME_BETWEEN_RELOADS = 30000;

// icon sizes
export const SMALL_ICON_SIZE = 16;
export const DEFAULT_ICON_SIZE = 24;
export const MEDIUM_ICON_SIZE = 40;
export const BIG_ICON_SIZE = 64;
export const GIANT_ICON_SIZE = 80;

// map marker sizes
export const SITE_MARKER_SIZE = 24;
export const SMALL_CLUSTER_SIZE = 42;
export const MEDIUM_CLUSTER_SIZE = 50;
export const BIG_CLUSTER_SIZE = 60;

// numbers
export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const NINE = 9;
export const TEN = 10;
export const TWENTY = 20;
export const THIRTY = 30;
export const SIXTY = 60;
export const NINETY = 90;
export const ONE_HUNDRED = 100;
export const ONE_THOUSAND = 1000;

// tooltip positions
export const LEFT = 'left';
export const RIGHT = 'right';

// map constants
export const DEFAULT_ZOOM = 3.5;
export const DEFAULT_CENTER = [-98.6, 38.7];

// paths
export const SNOW_PATH = '/snow';
export const ESTIMATED_SNOW_DEPTH_PATH = '/estimated-snow-depth';
export const SNOW_ACCUMULATION_PATH = '/snow-accumulation';
export const TEMPERATURE_PATH = '/temperature';
export const EDIT_SITE_GROUPS_PATH = '/edit-site-groups';
export const PATHS_WITH_AERIS_LAYERS = [
  SNOW_PATH,
  ESTIMATED_SNOW_DEPTH_PATH,
  SNOW_ACCUMULATION_PATH,
  TEMPERATURE_PATH
];

// select options
export const OBSERVATION_FORECAST_OPTIONS = [
  { label: 'Observation', value: 'observation' },
  { label: 'Forecast', value: 'forecast' }
];

export const ALASKA = 'AK';
export const USA = 'US';

export const REGION_OPTIONS = [
  { label: 'USA', value: USA, zoom: DEFAULT_ZOOM, center: DEFAULT_CENTER },
  { label: 'Alaska', value: ALASKA, zoom: 3.5, center: [-149.3, 63.7] }
  // { label: 'Canada', value: 'CA', zoom: 2.6, center: [-107.8, 59.0] }
];

// site statuses
export const SERVICE_PERFORMED = 'SERVICE_PERFORMED';
export const SERVICE_MISSED = 'SERVICE_MISSED';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const SERVICE_CONFIRMED = 'SERVICE_CONFIRMED';

// weather radar animations
export const FRAME_COUNT = 12; // total intervals (START_MINUTES / FRAME_COUNT === minutes between frames)
export const START_MINUTES = -360; // start time offset relative to now, where negative means past
export const END_MINUTES = 360;
export const AERISWEATHER = 'aerisweather';

// not available things
export const NA = 'N/A';
export const NOT_AVAILABLE = '(not available)';

// Roles
export const INTERNAL_ROLE = 'Feature Role - Internal User';
