import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  ActionLabelS,
  Box,
  Flex,
  HorizontalRule,
  InfoLabelM,
  Text2XL,
  Text2XS,
  Text5XL,
  TextL,
  TextM,
  VerticalRule
} from '@kbs/kbsforce-components';
import { GIANT_ICON_SIZE, MEDIUM_ICON_SIZE, NA } from '../utils/constants';

// TODO: investigate more about react lazy
// because it doesn't make sense to import all these things
// when i will need only one icon per rendered component
import { ReactComponent as Blizzard } from '../assets/blizzard.svg';
import { ReactComponent as BlowingSnow } from '../assets/blowingsnow.svg';
import { ReactComponent as Cloudy } from '../assets/cloudy.svg';
import { ReactComponent as CloudyW } from '../assets/cloudyw.svg';
import { ReactComponent as Cold } from '../assets/cold.svg';
import { ReactComponent as Drizzle } from '../assets/drizzle.svg';
import { ReactComponent as DrizzleF } from '../assets/drizzlef.svg';
import { ReactComponent as Dust } from '../assets/dust.svg';
import { ReactComponent as Fair } from '../assets/fair.svg';
import { ReactComponent as FairN } from '../assets/fairn.svg';
import { ReactComponent as Flurries } from '../assets/flurries.svg';
import { ReactComponent as Fog } from '../assets/fog.svg';
import { ReactComponent as FreezingRain } from '../assets/freezingrain.svg';
import { ReactComponent as Hazy } from '../assets/hazy.svg';
import { ReactComponent as HazyN } from '../assets/hazyn.svg';
import { ReactComponent as Hot } from '../assets/hot.svg';
import { ReactComponent as MCloudy } from '../assets/mcloudy.svg';
import { ReactComponent as MCloudyN } from '../assets/mcloudyn.svg';
import { ReactComponent as MCloudyR } from '../assets/mcloudyr.svg';
import { ReactComponent as MCloudyRN } from '../assets/mcloudyrn.svg';
import { ReactComponent as MCloudyRW } from '../assets/mcloudyrw.svg';
import { ReactComponent as MCloudyRWN } from '../assets/mcloudyrwn.svg';
import { ReactComponent as MCloudyS } from '../assets/mcloudys.svg';
import { ReactComponent as MCloudySF } from '../assets/mcloudysf.svg';
import { ReactComponent as MCloudySFN } from '../assets/mcloudysfn.svg';
import { ReactComponent as MCloudySFW } from '../assets/mcloudysfw.svg';
import { ReactComponent as MCloudySFWN } from '../assets/mcloudysfwn.svg';
import { ReactComponent as MCloudySN } from '../assets/mcloudysn.svg';
import { ReactComponent as MCloudySW } from '../assets/mcloudysw.svg';
import { ReactComponent as MCloudySWN } from '../assets/mcloudyswn.svg';
import { ReactComponent as MCloudyT } from '../assets/mcloudyt.svg';
import { ReactComponent as MCloudyTN } from '../assets/mcloudytn.svg';
import { ReactComponent as MCloudyTW } from '../assets/mcloudytw.svg';
import { ReactComponent as MCloudyTWN } from '../assets/mcloudytwn.svg';
import { ReactComponent as MCloudyW } from '../assets/mcloudyw.svg';
import { ReactComponent as MCloudyWN } from '../assets/mcloudywn.svg';
import { ReactComponent as Na } from '../assets/na.svg';
import { ReactComponent as PCloudy } from '../assets/pcloudy.svg';
import { ReactComponent as PCloudyN } from '../assets/pcloudyn.svg';
import { ReactComponent as PCloudyR } from '../assets/pcloudyr.svg';
import { ReactComponent as PCloudyRN } from '../assets/pcloudyrn.svg';
import { ReactComponent as PCloudyRW } from '../assets/pcloudyrw.svg';
import { ReactComponent as PCloudyRWN } from '../assets/pcloudyrwn.svg';
import { ReactComponent as PCloudyS } from '../assets/pcloudys.svg';
import { ReactComponent as PCloudySF } from '../assets/pcloudysf.svg';
import { ReactComponent as PCloudySFN } from '../assets/pcloudysfn.svg';
import { ReactComponent as PCloudySFW } from '../assets/pcloudysfw.svg';
import { ReactComponent as PCloudySFWN } from '../assets/pcloudysfwn.svg';
import { ReactComponent as PCloudySN } from '../assets/pcloudysn.svg';
import { ReactComponent as PCloudySW } from '../assets/pcloudysw.svg';
import { ReactComponent as PCloudySWN } from '../assets/pcloudyswn.svg';
import { ReactComponent as PCloudyT } from '../assets/pcloudyt.svg';
import { ReactComponent as PCloudyTN } from '../assets/pcloudytn.svg';
import { ReactComponent as PCloudyTW } from '../assets/pcloudytw.svg';
import { ReactComponent as PCloudyTWN } from '../assets/pcloudytwn.svg';
import { ReactComponent as PCloudyW } from '../assets/pcloudyw.svg';
import { ReactComponent as PCloudyWN } from '../assets/pcloudywn.svg';
import { ReactComponent as Rain } from '../assets/rain.svg';
import { ReactComponent as RainAndSnow } from '../assets/rainandsnow.svg';
import { ReactComponent as RainToSnow } from '../assets/raintosnow.svg';
import { ReactComponent as RainW } from '../assets/rainw.svg';
import { ReactComponent as Showers } from '../assets/showers.svg';
import { ReactComponent as ShowersW } from '../assets/showersw.svg';
import { ReactComponent as Sleet } from '../assets/sleet.svg';
import { ReactComponent as SleetSnow } from '../assets/sleetsnow.svg';
import { ReactComponent as Smoke } from '../assets/smoke.svg';
import { ReactComponent as Snow } from '../assets/snow.svg';
import { ReactComponent as SnowShowers } from '../assets/snowshowers.svg';
import { ReactComponent as SnowShowersW } from '../assets/snowshowersw.svg';
import { ReactComponent as SnowToRain } from '../assets/snowtorain.svg';
import { ReactComponent as SnowW } from '../assets/snoww.svg';
import { ReactComponent as Sunny } from '../assets/sunny.svg';
import { ReactComponent as SunnyN } from '../assets/sunnyn.svg';
import { ReactComponent as SunnyW } from '../assets/sunnyw.svg';
import { ReactComponent as SunnyWN } from '../assets/sunnywn.svg';
import { ReactComponent as TStorm } from '../assets/tstorm.svg';
import { ReactComponent as TStormS } from '../assets/tstorms.svg';
import { ReactComponent as TStormSW } from '../assets/tstormsw.svg';
import { ReactComponent as Wind } from '../assets/wind.svg';
import { ReactComponent as WintryMix } from '../assets/wintrymix.svg';

import { ReactComponent as BlizzardSmall } from '../assets/blizzard_40x40.svg';
import { ReactComponent as BlowingSnowSmall } from '../assets/blowingsnow_40x40.svg';
import { ReactComponent as CloudySmall } from '../assets/cloudy_40x40.svg';
import { ReactComponent as CloudyWSmall } from '../assets/cloudyw_40x40.svg';
import { ReactComponent as ColdSmall } from '../assets/cold_40x40.svg';
import { ReactComponent as DrizzleSmall } from '../assets/drizzle_40x40.svg';
import { ReactComponent as DrizzleFSmall } from '../assets/drizzlef_40x40.svg';
import { ReactComponent as DustSmall } from '../assets/dust_40x40.svg';
import { ReactComponent as FairSmall } from '../assets/fair_40x40.svg';
import { ReactComponent as FairNSmall } from '../assets/fairn_40x40.svg';
import { ReactComponent as FlurriesSmall } from '../assets/flurries_40x40.svg';
import { ReactComponent as FogSmall } from '../assets/fog_40x40.svg';
import { ReactComponent as FreezingRainSmall } from '../assets/freezingrain_40x40.svg';
import { ReactComponent as HazySmall } from '../assets/hazy_40x40.svg';
import { ReactComponent as HazyNSmall } from '../assets/hazyn_40x40.svg';
import { ReactComponent as HotSmall } from '../assets/hot_40x40.svg';
import { ReactComponent as MCloudySmall } from '../assets/mcloudy_40x40.svg';
import { ReactComponent as MCloudyNSmall } from '../assets/mcloudyn_40x40.svg';
import { ReactComponent as MCloudyRSmall } from '../assets/mcloudyr_40x40.svg';
import { ReactComponent as MCloudyRNSmall } from '../assets/mcloudyrn_40x40.svg';
import { ReactComponent as MCloudyRWSmall } from '../assets/mcloudyrw_40x40.svg';
import { ReactComponent as MCloudyRWNSmall } from '../assets/mcloudyrwn_40x40.svg';
import { ReactComponent as MCloudySSmall } from '../assets/mcloudys_40x40.svg';
import { ReactComponent as MCloudySFSmall } from '../assets/mcloudysf_40x40.svg';
import { ReactComponent as MCloudySFNSmall } from '../assets/mcloudysfn_40x40.svg';
import { ReactComponent as MCloudySFWSmall } from '../assets/mcloudysfw_40x40.svg';
import { ReactComponent as MCloudySFWNSmall } from '../assets/mcloudysfwn_40x40.svg';
import { ReactComponent as MCloudySNSmall } from '../assets/mcloudysn_40x40.svg';
import { ReactComponent as MCloudySWSmall } from '../assets/mcloudysw_40x40.svg';
import { ReactComponent as MCloudySWNSmall } from '../assets/mcloudyswn_40x40.svg';
import { ReactComponent as MCloudyTSmall } from '../assets/mcloudyt_40x40.svg';
import { ReactComponent as MCloudyTNSmall } from '../assets/mcloudytn_40x40.svg';
import { ReactComponent as MCloudyTWSmall } from '../assets/mcloudytw_40x40.svg';
import { ReactComponent as MCloudyTWNSmall } from '../assets/mcloudytwn_40x40.svg';
import { ReactComponent as MCloudyWSmall } from '../assets/mcloudyw_40x40.svg';
import { ReactComponent as MCloudyWNSmall } from '../assets/mcloudywn_40x40.svg';
import { ReactComponent as NaSmall } from '../assets/na_40x40.svg';
import { ReactComponent as PCloudySmall } from '../assets/pcloudy_40x40.svg';
import { ReactComponent as PCloudyNSmall } from '../assets/pcloudyn_40x40.svg';
import { ReactComponent as PCloudyRSmall } from '../assets/pcloudyr_40x40.svg';
import { ReactComponent as PCloudyRNSmall } from '../assets/pcloudyrn_40x40.svg';
import { ReactComponent as PCloudyRWSmall } from '../assets/pcloudyrw_40x40.svg';
import { ReactComponent as PCloudyRWNSmall } from '../assets/pcloudyrwn_40x40.svg';
import { ReactComponent as PCloudySSmall } from '../assets/pcloudys_40x40.svg';
import { ReactComponent as PCloudySFSmall } from '../assets/pcloudysf_40x40.svg';
import { ReactComponent as PCloudySFNSmall } from '../assets/pcloudysfn_40x40.svg';
import { ReactComponent as PCloudySFWSmall } from '../assets/pcloudysfw_40x40.svg';
import { ReactComponent as PCloudySFWNSmall } from '../assets/pcloudysfwn_40x40.svg';
import { ReactComponent as PCloudySNSmall } from '../assets/pcloudysn_40x40.svg';
import { ReactComponent as PCloudySWSmall } from '../assets/pcloudysw_40x40.svg';
import { ReactComponent as PCloudySWNSmall } from '../assets/pcloudyswn_40x40.svg';
import { ReactComponent as PCloudyTSmall } from '../assets/pcloudyt_40x40.svg';
import { ReactComponent as PCloudyTNSmall } from '../assets/pcloudytn_40x40.svg';
import { ReactComponent as PCloudyTWSmall } from '../assets/pcloudytw_40x40.svg';
import { ReactComponent as PCloudyTWNSmall } from '../assets/pcloudytwn_40x40.svg';
import { ReactComponent as PCloudyWSmall } from '../assets/pcloudyw_40x40.svg';
import { ReactComponent as PCloudyWNSmall } from '../assets/pcloudywn_40x40.svg';
import { ReactComponent as RainSmall } from '../assets/rain_40x40.svg';
import { ReactComponent as RainAndSnowSmall } from '../assets/rainandsnow_40x40.svg';
import { ReactComponent as RainToSnowSmall } from '../assets/raintosnow_40x40.svg';
import { ReactComponent as RainWSmall } from '../assets/rainw_40x40.svg';
import { ReactComponent as ShowersSmall } from '../assets/showers_40x40.svg';
import { ReactComponent as ShowersWSmall } from '../assets/showersw_40x40.svg';
import { ReactComponent as SleetSmall } from '../assets/sleet_40x40.svg';
import { ReactComponent as SleetSnowSmall } from '../assets/sleetsnow_40x40.svg';
import { ReactComponent as SmokeSmall } from '../assets/smoke_40x40.svg';
import { ReactComponent as SnowSmall } from '../assets/snow_40x40.svg';
import { ReactComponent as SnowShowersSmall } from '../assets/snowshowers_40x40.svg';
import { ReactComponent as SnowShowersWSmall } from '../assets/snowshowersw_40x40.svg';
import { ReactComponent as SnowToRainSmall } from '../assets/snowtorain_40x40.svg';
import { ReactComponent as SnowWSmall } from '../assets/snoww_40x40.svg';
import { ReactComponent as SunnySmall } from '../assets/sunny_40x40.svg';
import { ReactComponent as SunnyNSmall } from '../assets/sunnyn_40x40.svg';
import { ReactComponent as SunnyWSmall } from '../assets/sunnyw_40x40.svg';
import { ReactComponent as SunnyWNSmall } from '../assets/sunnywn_40x40.svg';
import { ReactComponent as TStormSmall } from '../assets/tstorm_40x40.svg';
import { ReactComponent as TStormSSmall } from '../assets/tstorms_40x40.svg';
import { ReactComponent as TStormSWSmall } from '../assets/tstormsw_40x40.svg';
import { ReactComponent as WindSmall } from '../assets/wind_40x40.svg';
import { ReactComponent as WintryMixSmall } from '../assets/wintrymix_40x40.svg';

const getWeatherIcon = (iconName = '', small) => {
  const name = iconName.split('.')[0];
  switch (name) {
    case 'blizzard':
    case 'blizzardn':
      return small ? Blizzard : BlizzardSmall;
    case 'blowingsnow':
    case 'blowingsnown':
      return small ? BlowingSnow : BlowingSnowSmall;
    case 'clear':
    case 'sunny':
      return small ? Sunny : SunnySmall;
    case 'clearn':
    case 'sunnyn':
      return small ? SunnyN : SunnyNSmall;
    case 'cloudy':
    case 'cloudyn':
      return small ? Cloudy : CloudySmall;
    case 'cloudyw':
    case 'cloudywn':
      return small ? CloudyW : CloudyWSmall;
    case 'cold':
    case 'coldn':
      return small ? Cold : ColdSmall;
    case 'drizzle':
    case 'drizzlen':
      return small ? Drizzle : DrizzleSmall;
    case 'drizzlef':
    case 'fdrizzlen':
      return small ? DrizzleF : DrizzleFSmall;
    case 'dust':
    case 'dustn':
      return small ? Dust : DustSmall;
    case 'fair':
      return small ? Fair : FairSmall;
    case 'fairn':
      return small ? FairN : FairNSmall;
    case 'flurries':
    case 'flurriesn':
    case 'flurriesw':
    case 'flurrieswn':
      return small ? Flurries : FlurriesSmall;
    case 'fog':
    case 'fogn':
      return small ? Fog : FogSmall;
    case 'freezingrain':
    case 'freezingrainn':
      return small ? FreezingRain : FreezingRainSmall;
    case 'hazy':
      return small ? Hazy : HazySmall;
    case 'hazyn':
      return small ? HazyN : HazyNSmall;
    case 'hot':
      return small ? Hot : HotSmall;
    case 'mcloudy':
      return small ? MCloudy : MCloudySmall;
    case 'mcloudyn':
      return small ? MCloudyN : MCloudyNSmall;
    case 'mcloudyr':
      return small ? MCloudyR : MCloudyRSmall;
    case 'mcloudyrn':
      return small ? MCloudyRN : MCloudyRNSmall;
    case 'mcloudyrw':
      return small ? MCloudyRW : MCloudyRWSmall;
    case 'mcloudyrwn':
      return small ? MCloudyRWN : MCloudyRWNSmall;
    case 'mcloudys':
      return small ? MCloudyS : MCloudySSmall;
    case 'mcloudysn':
      return small ? MCloudySN : MCloudySNSmall;
    case 'mcloudysf':
      return small ? MCloudySF : MCloudySFSmall;
    case 'mcloudysfn':
      return small ? MCloudySFN : MCloudySFNSmall;
    case 'mcloudysfw':
      return small ? MCloudySFW : MCloudySFWSmall;
    case 'mcloudysfwn':
      return small ? MCloudySFWN : MCloudySFWNSmall;
    case 'mcloudysw':
      return small ? MCloudySW : MCloudySWSmall;
    case 'mcloudyswn':
      return small ? MCloudySWN : MCloudySWNSmall;
    case 'mcloudyt':
      return small ? MCloudyT : MCloudyTSmall;
    case 'mcloudytn':
      return small ? MCloudyTN : MCloudyTNSmall;
    case 'mcloudytw':
      return small ? MCloudyTW : MCloudyTWSmall;
    case 'mcloudytwn':
      return small ? MCloudyTWN : MCloudyTWNSmall;
    case 'mcloudyw':
      return small ? MCloudyW : MCloudyWSmall;
    case 'mcloudywn':
      return small ? MCloudyWN : MCloudyWNSmall;
    case 'na':
      return small ? Na : NaSmall;
    case 'pcloudy':
      return small ? PCloudy : PCloudySmall;
    case 'pcloudyn':
      return small ? PCloudyN : PCloudyNSmall;
    case 'pcloudyr':
      return small ? PCloudyR : PCloudyRSmall;
    case 'pcloudyrn':
      return small ? PCloudyRN : PCloudyRNSmall;
    case 'pcloudyrw':
      return small ? PCloudyRW : PCloudyRWSmall;
    case 'pcloudyrwn':
      return small ? PCloudyRWN : PCloudyRWNSmall;
    case 'pcloudys':
      return small ? PCloudyS : PCloudySSmall;
    case 'pcloudysn':
      return small ? PCloudySN : PCloudySNSmall;
    case 'pcloudysf':
      return small ? PCloudySF : PCloudySFSmall;
    case 'pcloudysfn':
      return small ? PCloudySFN : PCloudySFNSmall;
    case 'pcloudysfw':
      return small ? PCloudySFW : PCloudySFWSmall;
    case 'pcloudysfwn':
      return small ? PCloudySFWN : PCloudySFWNSmall;
    case 'pcloudysw':
      return small ? PCloudySW : PCloudySWSmall;
    case 'pcloudyswn':
      return small ? PCloudySWN : PCloudySWNSmall;
    case 'pcloudyt':
      return small ? PCloudyT : PCloudyTSmall;
    case 'pcloudytn':
      return small ? PCloudyTN : PCloudyTNSmall;
    case 'pcloudytw':
      return small ? PCloudyTW : PCloudyTWSmall;
    case 'pcloudytwn':
      return small ? PCloudyTWN : PCloudyTWNSmall;
    case 'pcloudyw':
      return small ? PCloudyW : PCloudyWSmall;
    case 'pcloudywn':
      return small ? PCloudyWN : PCloudyWNSmall;
    case 'rain':
    case 'rainn':
      return small ? Rain : RainSmall;
    case 'rainandsnow':
    case 'rainandsnown':
      return small ? RainAndSnow : RainAndSnowSmall;
    case 'raintosnow':
    case 'raintosnown':
      return small ? RainToSnow : RainToSnowSmall;
    case 'rainw':
    case 'rainwn':
      return small ? RainW : RainWSmall;
    case 'showers':
    case 'showersn':
      return small ? Showers : ShowersSmall;
    case 'showersw':
    case 'showerswn':
      return small ? ShowersW : ShowersWSmall;
    case 'sleet':
    case 'sleetn':
      return small ? Sleet : SleetSmall;
    case 'sleetsnow':
    case 'sleetsnown':
      return small ? SleetSnow : SleetSnowSmall;
    case 'smoke':
    case 'smoken':
      return small ? Smoke : SmokeSmall;
    case 'snow':
    case 'snown':
      return small ? Snow : SnowSmall;
    case 'snoww':
    case 'snowwn':
      return small ? SnowW : SnowWSmall;
    case 'snowshowers':
    case 'snowshowersn':
      return small ? SnowShowers : SnowShowersSmall;
    case 'snowshowersw':
    case 'snowshowerswn':
      return small ? SnowShowersW : SnowShowersWSmall;
    case 'snowtorain':
    case 'snowtorainn':
      return small ? SnowToRain : SnowToRainSmall;
    case 'sunnyw':
      return small ? SunnyW : SunnyWSmall;
    case 'sunnywn':
      return small ? SunnyWN : SunnyWNSmall;
    case 'tstorm':
    case 'tstormn':
      return small ? TStorm : TStormSmall;
    case 'tstorms':
    case 'tstormsn':
      return small ? TStormS : TStormSSmall;
    case 'tstormsw':
    case 'tstornswn':
      return small ? TStormSW : TStormSWSmall;
    case 'wind':
    case 'windn':
      return small ? Wind : WindSmall;
    case 'wintrymix':
    case 'wintrymixn':
      return small ? WintryMix : WintryMixSmall;
    default:
      return Box;
  }
};

const Measure = ({ name, value }) => (
  <>
    <Flex height="24px" justifyContent="space-between" alignItems="center">
      <InfoLabelM variant="Info-Label_M_Neutral-75">{name}:</InfoLabelM>
      <TextM variant="text_M_medium" style={{ marginLeft: 'auto' }}>
        {value}
      </TextM>
    </Flex>
    <HorizontalRule />
  </>
);

const HiLoTemps = ({ hi, lo }) => (
  <Flex gridColumnGap="16px">
    <Flex alignItems="baseline" gridColumnGap="4px">
      <Text2XS variant="text_2XS">Hi&nbsp;</Text2XS>
      <TextM variant="text_M_medium">{isNil(hi) ? NA : `${hi}º`}</TextM>
    </Flex>
    <Flex alignItems="baseline" gridColumnGap="4px">
      <Text2XS variant="text_2XS">Lo&nbsp;</Text2XS>
      <TextM variant="text_M_medium">{isNil(lo) ? NA : `${lo}º`}</TextM>
    </Flex>
  </Flex>
);

const WeatherConditions = ({
  small,
  time,
  weather,
  icon,
  temperature,
  snowCover,
  snowPrecipitation,
  humidity,
  cloudCover,
  phrase,
  minTemp,
  maxTemp
}) => {
  const { colors } = useTheme();
  const WeatherIcon = getWeatherIcon(icon, small);

  return (
    <Flex flexDirection="column" flexGrow="1">
      <Flex
        padding="4px"
        flexDirection={small ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        gridColumnGap="4px"
        style={{ backgroundColor: colors.neutral0 }}
      >
        <TextM variant="text_M_medium" style={{ textTransform: 'uppercase' }}>
          {moment(time).format('dddd')}
        </TextM>
        <TextM variant="text_M">
          {' '}
          {moment(time).format(small ? 'MMM D, YYYY' : 'MMMM D, YYYY, hh:mm a')}
        </TextM>
      </Flex>
      {small ? (
        <Flex flexDirection="column" alignItems="center" mt="16px" gridRowGap="16px">
          {WeatherIcon ? <WeatherIcon width={MEDIUM_ICON_SIZE} height={MEDIUM_ICON_SIZE} /> : null}
          <HiLoTemps hi={minTemp} lo={maxTemp} />
        </Flex>
      ) : (
        <Flex mt="16px" mb="16px" justifyContent="flex-start" alignItems="center">
          {WeatherIcon ? <WeatherIcon width={GIANT_ICON_SIZE} height={GIANT_ICON_SIZE} /> : null}
          <Box ml="20px" mr="32px">
            <Flex alignItems="baseline" mb="10px">
              <Text5XL variant="text_5XL">{temperature ?? NA}º</Text5XL>&nbsp;
              <Text2XL variant="text_2XL">F</Text2XL>
            </Flex>
            <HiLoTemps hi={minTemp} lo={maxTemp} />
          </Box>
          <TextL variant="text_L_medium">{weather ?? NA}</TextL>
        </Flex>
      )}

      <HorizontalRule />
      <Flex>
        <Box style={{ flexGrow: 1 }}>
          <Measure
            small={small}
            name="Snow Accumulation"
            value={isNil(snowPrecipitation) ? NA : `${snowPrecipitation} ${small ? 'in' : 'inch'}`}
          />
          {!small && (
            <Measure
              small={small}
              name="Snow Depth"
              value={isNil(snowCover) ? NA : `${snowCover} ${small ? 'in' : 'inch'}`}
            />
          )}
        </Box>
        {!small && (
          <>
            <VerticalRule />
            <Box style={{ flexGrow: 1 }}>
              <Measure name="Humidity" value={isNil(humidity) ? NA : `${humidity}%`} />
              <Measure name="Cloud Cover" value={isNil(cloudCover) ? NA : `${cloudCover}%`} />
            </Box>
          </>
        )}
      </Flex>
      {!small && (
        <Flex flexDirection="column" mt="32px" gridRowGap="8px">
          <ActionLabelS variant="Action-Label_S_Main-100">Today&apos;s forecast</ActionLabelS>
          <TextL variant="text_L">{phrase ?? NA}</TextL>
        </Flex>
      )}
    </Flex>
  );
};

export default WeatherConditions;
