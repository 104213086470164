import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { last } from 'lodash';
import { Box, Flex, getTheme, Layout, LogoSpinner, UserDropdown } from '@kbs/kbsforce-components';
import { Menu } from '../../components';
import { fetchConfig } from './actions';
import MapExplorer from '../MapExplorer';
import { isInsideIFrame } from '../../utils';
import { ANALYTICS_EVENTS, getPageViewParams } from '../../utils/analytics';
import {
  ESTIMATED_SNOW_DEPTH_PATH,
  INTERNAL_ROLE,
  SNOW_ACCUMULATION_PATH,
  SNOW_PATH,
  TEMPERATURE_PATH
} from '../../utils/constants';

const App = () => {
  const loadingSession = useSelector(store => store.app.loadingSession);
  const redirect = useSelector(store => store.app.redirect);
  const configuration = useSelector(store => store.app.configuration);
  const user = useSelector(store => store.app.user);
  const logoutUrl = useSelector(store => store.app.logoutUrl);
  const analyticsMeasurementId = useSelector(store => store.app.analyticsMeasurementId);

  const dispatch = useDispatch();
  const location = useLocation();

  // effect for fetching the config
  useEffect(() => {
    dispatch(fetchConfig());
  }, []);

  // effect for initializing google analytics
  useEffect(() => {
    if (!loadingSession) {
      // get user email domain
      const emailDomain = last((user?.email ?? '').split('@'));
      // set analytics 4
      if (analyticsMeasurementId) {
        const firstScript = document.createElement('script');
        firstScript.async = true;
        firstScript.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsMeasurementId}`;
        document.head.append(firstScript);

        const secondScript = document.createElement('script');
        secondScript.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', '${analyticsMeasurementId}', {
              send_page_view: false,
              customerId: '${
                (user?.roles ?? []).includes(INTERNAL_ROLE) ? '-' : user?.customerNumber
              }'
            });
            gtag('set', 'user_properties', {
              oneLoginDomain: '${emailDomain}'
            });
          `;
        document.head.append(secondScript);
      } else {
        // eslint-disable-next-line no-console
        console.error('No Analytics 4 measurement ID found!');
      }
    }
  }, [loadingSession, analyticsMeasurementId]);

  // effect for tracking pageviews (i.e. changing map layers)
  useEffect(() => {
    if (!loadingSession) {
      window.gtag?.('event', ANALYTICS_EVENTS.pageView, getPageViewParams(location.pathname));
    }
  }, [loadingSession, location.pathname]);

  let subtitle = '';
  switch (location.pathname) {
    case '/':
      subtitle = 'Sites Only';
      break;
    case SNOW_PATH:
      subtitle = 'Snow';
      break;
    case ESTIMATED_SNOW_DEPTH_PATH:
      subtitle = 'Estimated Snow Depth';
      break;
    case SNOW_ACCUMULATION_PATH:
      subtitle = 'Snow Accumulation';
      break;
    case TEMPERATURE_PATH:
      subtitle = 'Temperature';
      break;
    default:
      subtitle = '';
  }

  return (
    <ThemeProvider theme={getTheme()}>
      {redirect || !user || !configuration ? (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" height="100vh">
          <LogoSpinner />
          <Box mt="16px">
            <span>Loading config...</span>
          </Box>
        </Flex>
      ) : (
        <>
          {isInsideIFrame() ? (
            <MapExplorer />
          ) : (
            <Layout
              leftItems={{
                title: 'KBSForce™ SNOW MAP',
                subtitle,
                homeUrl: '/'
              }}
              rightItems={
                <UserDropdown
                  shortTitle={user.firstName}
                  title={`${user.firstName} ${user.lastName}`}
                  email={user.email}
                  logoutUrl={logoutUrl}
                />
              }
              sidebar={{
                isFloating: true,
                items: <Menu />
              }}
            >
              <MapExplorer />
            </Layout>
          )}
        </>
      )}
    </ThemeProvider>
  );
};

export default App;
