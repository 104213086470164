import React from 'react';
import { Box, Flex, TextS } from '@kbs/kbsforce-components';
import {
  ESTIMATED_SNOW_DEPTH_PATH,
  SNOW_ACCUMULATION_PATH,
  TEMPERATURE_PATH,
  SNOW_PATH
} from '../utils/constants';

const weatherRadarLegend = require('../assets/weather-radar-legend.svg').default;
const snowCoverLegend = require('../assets/snow-cover-legend.svg').default;
const snowPrecipitationLegend = require('../assets/snow-precipitation-legend.svg').default;
const temperatureLegend = require('../assets/temperature-legend.svg').default;

// the legends are rendered 1.25 times bigger than the real image sizes
const LEGEND_WIDTH = {
  [SNOW_PATH]: 412.5,
  [ESTIMATED_SNOW_DEPTH_PATH]: 480,
  [SNOW_ACCUMULATION_PATH]: 692.425,
  [TEMPERATURE_PATH]: 484.725
};
const LEGEND_HEIGHT = 45.625;

const MapLegend = ({ type }) => {
  let image = null;
  let unit = '';
  switch (type) {
    case SNOW_PATH:
      image = weatherRadarLegend;
      unit = 'dbz';
      break;
    case ESTIMATED_SNOW_DEPTH_PATH:
      image = snowCoverLegend;
      unit = 'in';
      break;
    case SNOW_ACCUMULATION_PATH:
      image = snowPrecipitationLegend;
      unit = 'in';
      break;
    case TEMPERATURE_PATH:
      image = temperatureLegend;
      unit = 'ºF';
      break;
    default:
      image = null;
  }

  return image ? (
    <Flex alignItems="flex-end" style={{ opacity: '0.8' }}>
      <img src={image} alt="map legend" width={LEGEND_WIDTH[type]} height={LEGEND_HEIGHT} />
      <Box ml="16px">
        <TextS variant="text_S_low-line-spacing_Neutral-0">{unit}</TextS>
      </Box>
    </Flex>
  ) : null;
};

export default MapLegend;
