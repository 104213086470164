import { get } from './api';

const SESSION_ENDPOINT = '/api/session';
const FILTERS_ENDPOINT = '/api/filters';
const SITES_STATUS_ENDPOINT = '/api/sites-status';

const AERIS_URL = 'https://api.aerisapi.com';

export function getSession() {
  return get(SESSION_ENDPOINT, {
    RelayState: window.location.search
  });
}

export function getFilters(params) {
  return get(FILTERS_ENDPOINT, params);
}

export function getSitesStatus(params) {
  return get(SITES_STATUS_ENDPOINT, params);
}

export function getStatusDescriptions(params) {
  return get(`${SITES_STATUS_ENDPOINT}/descriptions`, params);
}

export function getSiteDetails(siteId, params) {
  return get(`${SITES_STATUS_ENDPOINT}/site/${siteId}`, params);
}

export function getAerisConditions(lat, long, params) {
  return get(`${AERIS_URL}/conditions/${lat},${long}`, params);
}

export function getAerisPhrases(lat, long, params) {
  return get(`${AERIS_URL}/phrases/summary/${lat},${long}`, params);
}

export function getAerisForecasts(lat, long, params) {
  return get(`${AERIS_URL}/forecasts/${lat},${long}`, params);
}

export function getAerisObservations(lat, long, params) {
  return get(`${AERIS_URL}/observations/${lat},${long}`, params);
}
