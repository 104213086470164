import { Arrow, Button, Chip, FilterIcon, Flex, ResetIcon } from '@kbs/kbsforce-components';
import React from 'react';

const FilterToggleButton = ({ appliedFilters, isOpen, onClick, onResetFilters }) => (
  <Flex alignItems="center" gridColumnGap="4px">
    <Button
      variant="subtle"
      subvariant="importance4"
      onClick={onClick}
      data-tooltip-content={isOpen ? 'Show less' : 'Show more'}
    >
      <FilterIcon small />
      Filters
      <Arrow up={isOpen} />
    </Button>
    <Chip
      variant={appliedFilters ? 'accent' : 'neutral'}
      subvariant={appliedFilters ? 'light' : 'outlined'}
      size="2XS"
    >
      {appliedFilters}
    </Chip>
    <Button
      variant="subtle"
      subvariant="importance4"
      onClick={onResetFilters}
      data-tooltip-content="Reset Filters"
      data-tooltip-place="right"
    >
      <ResetIcon small />
    </Button>
  </Flex>
);

export default FilterToggleButton;
