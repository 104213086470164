import {
  Box,
  CrossIcon,
  Flex,
  HeadingL,
  HorizontalRule,
  IconButton,
  InfoLabelM
} from '@kbs/kbsforce-components';
import React from 'react';
import { useTheme } from 'styled-components';
import { DEFAULT_CONTAINER_PADDING } from '../utils/constants';

const SidePopUp = ({ type, title, children, onClose }) => {
  const { colors } = useTheme();
  return (
    <Flex
      width="100%"
      height="100%"
      flexDirection="column"
      style={{ overflow: 'hidden', boxSizing: 'border-box' }}
    >
      <Box width="100%" p={DEFAULT_CONTAINER_PADDING} pb="0px" style={{ position: 'sticky' }}>
        <Flex justifyContent="flex-end">
          <IconButton onClick={onClose}>
            <CrossIcon color={colors.main300} />
          </IconButton>
        </Flex>
        <Box px="12px">
          <InfoLabelM variant="Info-Label_M">{type}</InfoLabelM>
          <Box mt="8px">
            <HeadingL variant="Heading_L_Neutral-100">{title}</HeadingL>
          </Box>
          <HorizontalRule color={colors.neutral100} />
        </Box>
      </Box>
      <Flex
        maxHeight="100%"
        p="28px"
        pt="20px"
        flexDirection="column"
        style={{ overflow: 'auto', boxSizing: 'border-box' }}
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default SidePopUp;
