import {
  capitalize,
  get,
  filter as _filter,
  isArray,
  map,
  orderBy,
  pick,
  uniqBy,
  isEqual,
  isNil
} from 'lodash';
import {
  ALASKA,
  BIG_CLUSTER_SIZE,
  FOUR,
  MEDIUM_CLUSTER_SIZE,
  ONE,
  ONE_HUNDRED,
  ONE_THOUSAND,
  PATHS_WITH_AERIS_LAYERS,
  PROD_URL,
  SITE_MARKER_SIZE,
  SMALL_CLUSTER_SIZE,
  ESTIMATED_SNOW_DEPTH_PATH,
  SNOW_ACCUMULATION_PATH,
  TEMPERATURE_PATH,
  USA,
  SNOW_PATH,
  ZERO
} from './constants';

export const mapOptions = ({
  items,
  labelKey,
  valueKey,
  extraKeys,
  allLabel,
  allValue,
  labelFn,
  valueFn,
  uniqFn,
  filter,
  sort
}) => {
  const all = allLabel ? [{ value: allValue, label: allLabel }] : [];
  const options = orderBy(
    uniqBy(
      map(_filter(items, filter), item => {
        const label = labelFn ? labelFn(item) : capitalize(get(item, labelKey));
        const value = valueFn ? valueFn(item) : valueKey ? get(item, valueKey) : label;
        return {
          value,
          label,
          ...(isArray(extraKeys) && pick(item, extraKeys))
        };
      }),
      uniqFn || 'value'
    ),
    sort
  );
  return [...all, ...options];
};

/**
 * Gets the number of applied filters (i.e, different from the default filters)
 * @param {Object} currentFilters the current filters (from redux)
 * @param {Object} defaultFilters the default filters
 * @returns the number of filters which are not equal to their default equivalents
 */
export const getAppliedFiltersNumber = (currentFilters, defaultFilters) =>
  Object.keys(defaultFilters).reduce(
    (total, key) => total + (isEqual(currentFilters[key], defaultFilters[key]) ? ZERO : ONE),
    ZERO
  );

/**
 * Converts a list of sites into a valid GeoJSON for using it in a map
 * @param {Array} sites the sites array as it come from the server response
 * @returns this thing but converted into a GeoJSON thing
 * @see https://en.wikipedia.org/wiki/GeoJSON#Example
 */
export const getMarkersAsGeoJSON = sites => {
  const features = sites
    .filter(site => !isNil(site.lat) && !isNil(site.lng))
    .map(site => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [site.lng, site.lat]
      },
      properties: {
        // the properties object contains additional props that could be used in the map
        // if you need to use a site prop for some new feature, add it inside this object
        id: site.siteId,
        name: site.siteName,
        status: site.status,
        lat: site.lat,
        long: site.lng
      }
    }));
  return {
    type: 'FeatureCollection',
    features
  };
};

/**
 * Creates the second line that will be displayed in a cluster popup
 * @param {Number} servicePerformedCount the number of markers with service performed status
 * @param {Number} serviceConfirmedCount the number of markers with service confirmed status
 * @param {Number} serviceMissedCount the number of markers with service missed status
 * @param {Number} activeCount the number of markers with active status
 * @param {Number} totalCount the total number of markers, no matter their statuses
 * @returns a string which indicates the service status of the sites
 */
export const getClusterPopupSecondLine = (
  servicePerformedCount,
  serviceConfirmedCount,
  serviceMissedCount,
  activeCount,
  totalCount
) =>
  serviceMissedCount === totalCount
    ? 'All with missing services'
    : serviceMissedCount > ZERO
    ? `${serviceMissedCount} with missing services`
    : activeCount === totalCount
    ? 'All awaiting service'
    : activeCount > ZERO
    ? `${activeCount} awaiting service`
    : serviceConfirmedCount > ZERO
    ? `${serviceConfirmedCount} with Service Confirmed by Phone`
    : servicePerformedCount === totalCount
    ? 'All correctly serviced'
    : '';

/**
 * Create an HTML-containing string to be used in a popup
 * @param {String} firstLine the first line (site name or points count)
 * @param {String} secondLine the second line (number of unserviced sites or measured thing)
 * @param {boolean} isRed indicates if the second line should be red (when there are sites with service missed)
 * @param {boolean} isBlue indicates if the second line should be blue (when all the sites have service performed)
 * @returns an html that looks like our tooltips
 */
export const createPopupHTML = (firstLine, secondLine, isRed, isBlue) => `
 <div style="font-family: 'Roboto', sans-serif;color: #fff;background-color: #272727;padding: 10px;border-radius: 4px;">
   ${firstLine || ''}<br />
   <span style="color: ${isRed ? '#e06c75' : isBlue ? '#8ac5e6' : 'white'}">
    ${secondLine ?? ''}
   </span>
 </div>
`;

/**
 * Gets the offset needed for a map popup (tooltip)
 * @param {int} pointCount the number of clustered sites for a cluster popup, undefined for a site popup
 * @returns a PointLike array with the needed offset
 */
export const getPopupOffset = pointCount => {
  // no point count, it's a site
  if (isNil(pointCount)) {
    return [-Math.ceil(SITE_MARKER_SIZE / 2), Math.ceil(SITE_MARKER_SIZE / 2) + FOUR];
  }
  // small cluster
  if (pointCount < ONE_HUNDRED) {
    return [-Math.ceil(SMALL_CLUSTER_SIZE / 2), Math.ceil(SMALL_CLUSTER_SIZE / 2) + FOUR];
  }
  // medium cluster
  if (pointCount < ONE_THOUSAND) {
    return [-Math.ceil(MEDIUM_CLUSTER_SIZE / 2), Math.ceil(MEDIUM_CLUSTER_SIZE / 2) + FOUR];
  }
  // big cluster
  return [-Math.ceil(BIG_CLUSTER_SIZE / 2), Math.ceil(BIG_CLUSTER_SIZE / 2) + FOUR];
};

/**
 * Gets the tiles needed for adding an Aeris source in Mapbox
 * @param {String} clientId the aeris client id
 * @param {String} clientSecret the aeris client secret
 * @param {String} path the current path
 * @param {String} interval the interval from when you want the tiles (omit for current tiles)
 * @returns an array with the four needed tiles
 * @see https://www.aerisweather.com/support/docs/aeris-maps/examples/mapbox-gl-api-integration/
 * @see https://www.aerisweather.com/support/docs/aeris-maps/reference/map-layers/#all
 */
export const getAerisTiles = (clientId, clientSecret, path, region = USA, interval = 'current') => {
  if (!PATHS_WITH_AERIS_LAYERS.includes(path)) {
    return null;
  }
  let typeCode = '';
  // the :xx numbers after the type codes are the opacity for each layer
  // use a number from [0..99], omit it for a 100% opacity
  switch (path) {
    case SNOW_PATH:
      typeCode = 'fqsf-1h:80';
      break;
    case ESTIMATED_SNOW_DEPTH_PATH:
      typeCode = region === ALASKA ? 'snow-depth-global:80' : 'snow-depth:80';
      break;
    case SNOW_ACCUMULATION_PATH:
      typeCode = 'fqsf-accum-hrrr:80';
      break;
    case TEMPERATURE_PATH:
      typeCode = 'temperatures:50';
      break;
    default:
      typeCode = '';
  }
  return [
    `https://maps1.aerisapi.com/${clientId}_${clientSecret}/${typeCode}/{z}/{x}/{y}/${interval}.png`,
    `https://maps2.aerisapi.com/${clientId}_${clientSecret}/${typeCode}/{z}/{x}/{y}/${interval}.png`,
    `https://maps3.aerisapi.com/${clientId}_${clientSecret}/${typeCode}/{z}/{x}/{y}/${interval}.png`,
    `https://maps4.aerisapi.com/${clientId}_${clientSecret}/${typeCode}/{z}/{x}/{y}/${interval}.png`
  ];
};

/**
 * Gets the NetSuite URL to be used in the Recent Cases table
 * @param {Number} srcId the srcId property from the case
 * @returns the production URL if you are in the prod environment, the staging URL otherwise
 */
export const getNetSuiteCaseURL = srcId =>
  window.location.hostname.includes(PROD_URL)
    ? `https://6240361.app.netsuite.com/app/crm/support/supportcase.nl?id=${srcId}`
    : `https://6240361-sb2.app.netsuite.com/app/crm/support/supportcase.nl?id=${srcId}`;

/**
 * Checks if the whole site is running inside an iframe
 * @returns `true` if this is in an iframe, `false` otherwise
 */
export const isInsideIFrame = () => window.self !== window.top;

/**
 * It transforms the parameters given from window.location.href to an Object.
 * @param {string} location, it is the location got from window.location.href
 * @returns a JSON object with the parameters
 */
export const parseUrlParams = location => {
  const params = location.split('?')[1];
  const paramsResult = {};
  if (!params) return paramsResult;
  params.split('&').forEach(param => {
    const prop = param.split('=');
    paramsResult[prop[0]] = prop[1];
  });
  return paramsResult;
};
