import {
  AlertNotificationIcon,
  Box,
  Collapsible,
  ExteriorServicesSnowIcon,
  Flex,
  HeadingL,
  HeadingS,
  LoadingSpinner,
  LogoSpinner,
  MetadataTable,
  NextDaysIcon,
  SnowEventIcon,
  SuccessNotificationIcon,
  Table,
  TableProvider,
  TextM,
  TodayIcon,
  VerticalRule
} from '@kbs/kbsforce-components';
import { get, isNil } from 'lodash';
import React, { useEffect } from 'react';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { ManagerContactInfo, SidePopUp, WeatherConditions } from '../../components';
import { isInsideIFrame } from '../../utils';
import {
  ACTIVE,
  INTERNAL_ROLE,
  NOT_AVAILABLE,
  SERVICE_MISSED,
  SERVICE_PERFORMED,
  SERVICE_CONFIRMED,
  ZERO
} from '../../utils/constants';
import { RECENT_CASES_COLUMNS, RECENT_VENDOR_PUNCHES_COLUMNS } from '../../utils/tableColumns';
import { componentMount, componentUnmount } from './actions';

const SiteDetails = ({ siteId, siteStatus, onClose }) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();

  const isInternalUser = useSelector(store => store.app.user.roles.includes(INTERNAL_ROLE));

  const loadingSite = useSelector(store => store.siteDetails.loadingSite);
  const loadingCurrentConditions = useSelector(store => store.siteDetails.loadingCurrentConditions);
  const site = useSelector(store => store.siteDetails.site);
  const currentConditions = useSelector(store => store.siteDetails.currentConditions);
  const next3DaysConditions = useSelector(store => store.siteDetails.next3DaysConditions);

  useEffect(() => {
    dispatch(componentMount(siteId));
    return () => dispatch(componentUnmount());
  }, [siteId]);

  const siteName = `${get(site, 'siteName') ?? '-'} ${get(site, 'city') ?? '-'} ${
    get(site, 'siteNumber') ?? '-'
  } (${get(site, 'siteId')})`;
  const vendor = get(site, 'vendors[0]');
  const vendorText = isNil(vendor)
    ? NOT_AVAILABLE
    : `${get(vendor, 'id', NOT_AVAILABLE)} - ${get(vendor, 'name', NOT_AVAILABLE)}`;
  const emailSubject = `${get(site, 'siteId') || siteId}${!isNil(siteName) && ` - ${siteName}`}`;

  const recentCases = get(site, 'recentCases', []);
  const recentVendorPunches = get(site, 'recentVendorPunches', []);
  const siteTimeZone = get(site, 'timeZone');

  return loadingSite ? (
    <Flex flex="1" justifyContent="center" alignItems="center">
      <LogoSpinner />
    </Flex>
  ) : (
    <Media queries={{ siteAddress: '(min-width: 1357px' }}>
      {matches => (
        <SidePopUp type="Site" title={siteName} onClose={onClose}>
          <MetadataTable
            rows={[
              [{ label: 'Address', value: site?.address1 ?? NOT_AVAILABLE }],
              [
                {
                  label: 'City',
                  value: site?.city ?? NOT_AVAILABLE,
                  width: matches.siteAddress ? '25%' : '50%'
                },
                {
                  label: 'State',
                  value: site?.state ?? NOT_AVAILABLE,
                  width: matches.siteAddress ? '25%' : '50%'
                },
                ...(matches.siteAddress
                  ? [
                      {
                        label: 'Zip',
                        value: site?.zipCode ?? NOT_AVAILABLE,
                        width: '25%'
                      },
                      {
                        label: 'KBS #',
                        value: site?.siteId ?? siteId,
                        width: '25%'
                      }
                    ]
                  : [])
              ],
              ...(!matches.siteAddress
                ? [
                    [
                      {
                        label: 'Zip',
                        value: site?.zipCode ?? NOT_AVAILABLE,
                        width: '50%'
                      },
                      {
                        label: 'KBS #',
                        value: site?.siteId ?? siteId,
                        width: '50%'
                      }
                    ]
                  ]
                : []),
              ...(!isInsideIFrame() && isInternalUser
                ? [
                    [
                      {
                        label: 'Primary Vendor',
                        value: (
                          <>
                            <TextM variant="text_M">{vendorText}</TextM>
                            {!isNil(vendor) && (
                              <ManagerContactInfo
                                phoneNumber={get(vendor, 'phone')}
                                email={get(vendor, 'email')}
                                emailSubject={emailSubject}
                              />
                            )}
                          </>
                        )
                      }
                    ]
                  ]
                : [])
            ]}
          />

          <Box mt="60px" mb="20px">
            <HeadingL variant="Heading_L">Weather</HeadingL>
          </Box>
          <Collapsible
            label={
              <Flex alignItems="center" gridColumnGap="8px">
                <TodayIcon color={colors.main100} />
                Current Conditions
              </Flex>
            }
            defaultOpen
            showRule
          >
            {loadingCurrentConditions && <LoadingSpinner text="Loading Current Conditions..." />}
            {!loadingCurrentConditions && isNil(currentConditions) && (
              <TextM variant="text_M_Neutral-45">Weather Information Not Available</TextM>
            )}
            {!loadingCurrentConditions && !isNil(currentConditions) && (
              <WeatherConditions
                time={currentConditions.dateTimeISO}
                weather={currentConditions.weather}
                weatherPrimaryCoded={currentConditions.weatherPrimaryCoded}
                icon={currentConditions.icon}
                isDay={currentConditions.isDay}
                temperature={currentConditions.tempF}
                snowCover={currentConditions.snowDepthIN}
                snowPrecipitation={currentConditions.snowIN}
                humidity={currentConditions.humidity}
                cloudCover={currentConditions.sky}
                phrase={currentConditions.phrase}
                minTemp={currentConditions.maxTempF}
                maxTemp={currentConditions.minTempF}
              />
            )}
          </Collapsible>
          <Box mt="52px">
            <Collapsible
              label={
                <Flex alignItems="center" gridColumnGap="8px">
                  <NextDaysIcon color={colors.main100} />
                  Next 3 Days
                </Flex>
              }
              showRule
            >
              <Flex>
                {next3DaysConditions.map((condition, index) => (
                  <>
                    <WeatherConditions
                      small
                      time={condition.dateTimeISO}
                      weatherPrimaryCoded={condition.weatherPrimaryCoded}
                      icon={condition.icon}
                      isDay={condition.isDay}
                      snowCover={condition.snowDepthIN}
                      snowPrecipitation={condition.snowIN}
                      minTemp={condition.maxTempF}
                      maxTemp={condition.minTempF}
                    />
                    {index !== next3DaysConditions.length - 1 && <VerticalRule />}
                  </>
                ))}
              </Flex>
            </Collapsible>
          </Box>

          {!isInsideIFrame() && isInternalUser && (
            <>
              <Box mt="60px" mb="20px">
                <HeadingL variant="Heading_L">Snow Services</HeadingL>
              </Box>
              <Collapsible
                label={
                  <Flex alignItems="center" gridColumnGap="8px">
                    <ExteriorServicesSnowIcon color={colors.main100} />
                    Services Status
                  </Flex>
                }
                defaultOpen
              >
                <Flex alignItems="center" mt="16px" gridColumnGap="16px">
                  {siteStatus === SERVICE_PERFORMED && <SuccessNotificationIcon />}
                  {siteStatus === SERVICE_CONFIRMED && <SuccessNotificationIcon />}
                  {siteStatus === SERVICE_MISSED && <AlertNotificationIcon />}
                  {siteStatus === ACTIVE && <SnowEventIcon color={colors.neutral45} />}
                  <TextM variant="text_M_medium">
                    {siteStatus === SERVICE_PERFORMED && 'All Serviced'}
                    {siteStatus === SERVICE_CONFIRMED && 'All Serviced'}
                    {siteStatus === SERVICE_MISSED &&
                      `${recentCases.length} Cases created from Snowmap`}
                    {siteStatus === ACTIVE && 'Awaiting Service'}
                  </TextM>
                </Flex>
                <Box mt="32px">
                  {siteStatus !== SERVICE_MISSED && (
                    <HeadingS variant="Heading_S">Recent Cases</HeadingS>
                  )}
                  {recentCases.length === ZERO ? (
                    <Box mt="8px">
                      <TextM variant="text_M_Neutral-45">No recent cases found</TextM>
                    </Box>
                  ) : (
                    <TableProvider
                      data={recentCases}
                      columns={RECENT_CASES_COLUMNS}
                      meta={{ siteTimeZone }}
                    >
                      <Table />
                    </TableProvider>
                  )}
                </Box>
              </Collapsible>
              <Box mt="52px">
                <Collapsible
                  label={
                    <Flex alignItems="center" gridColumnGap="8px">
                      <ExteriorServicesSnowIcon />
                      Recent Vendor Punches for Snow Removal
                    </Flex>
                  }
                  defaultOpen
                >
                  {recentVendorPunches.length === ZERO ? (
                    <TextM variant="text_M_Neutral-45">No recent vendor punches found</TextM>
                  ) : (
                    <TableProvider
                      data={recentVendorPunches}
                      columns={RECENT_VENDOR_PUNCHES_COLUMNS}
                      meta={{ siteTimeZone }}
                    >
                      <Table />
                    </TableProvider>
                  )}
                </Collapsible>
              </Box>
            </>
          )}
        </SidePopUp>
      )}
    </Media>
  );
};

export default SiteDetails;
