import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';
import { Button, Flex, PauseIcon, PlayIcon } from '@kbs/kbsforce-components';
import { END_MINUTES, FRAME_COUNT, START_MINUTES, TIME_FORMAT, ZERO } from '../utils/constants';

const Container = styled(Flex)`
  height: 38px;
  background-color: ${props => props.theme.colors.neutral100};
  border-radius: 4px;
`;

const TimelineContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 6px 11px 5px 11px;
  ${props =>
    props.small &&
    css`
      justify-content: center;
    `}
`;

const Times = styled(Flex)`
  transform: translateY(-7px);
  justify-content: center;
  align-items: center;
`;

const Slider = styled.div`
  margin-top: 3px;
  margin-bottom: 3px;
  width: calc(100% - 35px);
  min-height: 1px;
  height: 1px;
  background-color: #707070;
`;

const Tick = styled.div`
  width: 1px;
  height: 7px;
  background-color: #707070;
`;

const SliderHead = styled.div`
  width: 7px;
  height: 7px;
  background-color: white;
  border: 1px solid #707070;
  border-radius: 50%;
  box-sizing: border-box;
`;

const Hour = styled.div`
  font-size: 14px;
  color: white;
  width: 35px;
  height: 16px;
`;

const HourIndicator = ({ isCurrent, small, lastChild, children }) =>
  small ? (
    isCurrent ? (
      <Hour>{children}</Hour>
    ) : null
  ) : (
    <Flex
      height="27px"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      mr={lastChild ? '0px' : '20px'}
    >
      {isCurrent ? <SliderHead /> : <Tick />}
      <Hour>{children}</Hour>
    </Flex>
  );

const Now = styled.div`
  font-size: 13px;
  color: #ccc;
  width: 26px;
  height: 15px;
`;

const NowTick = styled.div`
  width: 1px;
  height: 14px;
  background-color: #707070;
  opacity: 0.5;
`;

const NowIndicator = ({ left }) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    style={{ position: 'absolute', top: '-16px', left }}
  >
    <Now>Now</Now>
    <NowTick />
  </Flex>
);

const AnimationPlayer = ({ currentOffset, isPlaying, onPlayPause, small }) => {
  const startTime = moment().minutes(ZERO).add(START_MINUTES, 'minutes');
  const timeForTheIterator = moment(startTime);
  const interval = (END_MINUTES - START_MINUTES) / FRAME_COUNT;
  const hourIndicators = [];
  for (let i = 0; i < FRAME_COUNT; i++) {
    hourIndicators.push(
      <HourIndicator
        key={i}
        isCurrent={i === currentOffset}
        small={small}
        lastChild={i === FRAME_COUNT - 1}
      >
        {timeForTheIterator.format(TIME_FORMAT)}
      </HourIndicator>
    );
    timeForTheIterator.add(interval, 'minutes');
  }

  // 54px === 1 hour
  // 16px is the offset between the TimeLineContainer border and the first hour indicator
  const nowIndicatorLeft =
    16 + Math.round((Math.abs(moment().diff(startTime, 'minutes')) * 54) / 60);

  return (
    <Container>
      <Button onClick={onPlayPause}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</Button>
      <TimelineContainer small={small}>
        <NowIndicator left={nowIndicatorLeft} />
        {!small ? (
          <>
            <Slider />
            <Times>{hourIndicators}</Times>
          </>
        ) : (
          hourIndicators
        )}
      </TimelineContainer>
    </Container>
  );
};

export default AnimationPlayer;
