import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Media from 'react-media';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Flex,
  RefreshIcon,
  Select,
  SnowMapSingleLocationIcon,
  TextM,
  TextS
} from '@kbs/kbsforce-components';
import {
  AnimationPlayer,
  FilterToggleButton,
  Mapbox,
  MapLegend,
  MapOverlayButton,
  MapOverlayPanel
} from '../../components';
import { getMarkersAsGeoJSON, isInsideIFrame, parseUrlParams } from '../../utils';
import {
  ACTIVE,
  BOTTOM_OVERLAY_HEIGHT,
  DATE_FORMAT,
  DEFAULT_CONTAINER_PADDING,
  LEFT,
  INTERNAL_ROLE,
  ONE,
  PATHS_WITH_AERIS_LAYERS,
  REGION_OPTIONS,
  SERVICE_MISSED,
  SERVICE_PERFORMED,
  SERVICE_CONFIRMED,
  ESTIMATED_SNOW_DEPTH_PATH,
  SNOW_ACCUMULATION_PATH,
  TEMPERATURE_PATH,
  TOP_OVERLAY_HEIGHT,
  SNOW_PATH,
  TIME_BETWEEN_RELOADS,
  INACTIVE
} from '../../utils/constants';
import SiteDetails from '../SiteDetails';
import {
  changeFilter,
  clearFilter,
  componentMount,
  filterReload,
  getSiteWeatherConditions,
  setCustomer
} from './actions';
import { ANALYTICS_EVENTS } from '../../utils/analytics';

let timeoutForReloading = null;

const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const MapExplorer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { colors } = useTheme();

  const mbApiKey = useSelector(store => store.app.mbApiKey);
  const aerisClientId = useSelector(store => store.app.aerisClientId);
  const aerisClientSecret = useSelector(store => store.app.aerisClientSecret);
  const roles = useSelector(store => store.app.user.roles);

  const shouldShowLoading = useSelector(store => store.mapExplorer.shouldShowLoading);
  const loadingFilters = useSelector(store => store.mapExplorer.loadingFilters);
  const loadingSitesStatus = useSelector(store => store.mapExplorer.loadingSitesStatus);
  const loadingStatusDescriptions = useSelector(
    store => store.mapExplorer.loadingStatusDescriptions
  );
  const customerOptions = useSelector(store => store.mapExplorer.customerOptions);
  const sitesStatuses = useSelector(store => store.mapExplorer.sitesStatuses);
  const siteOptions = useSelector(store => store.mapExplorer.siteOptions);
  const stateOptions = useSelector(store => store.mapExplorer.stateOptions);
  const cityOptions = useSelector(store => store.mapExplorer.cityOptions);
  const bannerOptions = useSelector(store => store.mapExplorer.bannerOptions);
  const hierarchyOptions = useSelector(store => store.mapExplorer.hierarchyOptions);
  const subcontractorOptions = useSelector(store => store.mapExplorer.subcontractorOptions);
  const serviceStatusOptions = useSelector(store => store.mapExplorer.serviceStatusOptions);
  const siteWeatherConditions = useSelector(store => store.mapExplorer.siteWeatherConditions);
  const filter = useSelector(store => store.mapExplorer.filter);
  const appliedFilters = useSelector(store => store.mapExplorer.appliedFilters);
  const customerId = useSelector(store => store.mapExplorer.customerId);

  const [showFilters, setShowFilters] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  // current offset from the weather radar animation
  const [currentOffset, setCurrentOffset] = useState(0);
  // indicates if the animation should be playing
  const [playingAnimation, setPlayingAnimation] = useState(false);

  const isLoading = shouldShowLoading && (loadingFilters || loadingSitesStatus);
  const isInternalUser = roles.includes(INTERNAL_ROLE);

  const [isMapLoaded, setIsMapLoaded] = useState(false);

  // effect for dispatching mount action
  useEffect(() => {
    dispatch(componentMount());
    return clearTimeout(timeoutForReloading);
  }, []);

  // effect for the auto reload
  useEffect(() => {
    if (!loadingSitesStatus) {
      clearTimeout(timeoutForReloading);
      timeoutForReloading = setTimeout(() => {
        dispatch(filterReload());
      }, TIME_BETWEEN_RELOADS);
    }
  }, [loadingSitesStatus]);

  // // effect to reload when some parameter change
  const param = location.search;
  useEffect(() => {
    const locationHref = window.location.href;
    const params = parseUrlParams(locationHref);
    if (isInsideIFrame()) return;
    if (customerId !== params.customerId) {
      dispatch(setCustomer(params.customerId));
      dispatch(filterReload());
    }
  }, [param]);

  const onToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const onFilterChange = key => value => {
    dispatch(changeFilter(key, value));
  };

  const onFilterClear = () => {
    dispatch(clearFilter());
  };

  const onFilterRefresh = () => {
    dispatch(filterReload());
  };

  const onGetSiteConditions = async (site, weather) => {
    await dispatch(getSiteWeatherConditions(site, weather));
  };

  const onTogglePlaying = () => {
    setPlayingAnimation(!playingAnimation);
  };

  const onCurrentOffsetChange = offset => {
    setCurrentOffset(offset);
  };

  const onMarkerClick = site => {
    setSelectedSite(site);
    window.gtag?.('event', ANALYTICS_EVENTS.siteDetailsOpen);
  };

  const onCloseDetails = () => {
    setSelectedSite(null);
  };

  const markerSites = isMapLoaded
    ? get(filter, 'site.value') === -ONE
      ? sitesStatuses
      : [sitesStatuses.find(s => s.siteId === get(filter, 'site.value'))]
    : [];

  const markers = getMarkersAsGeoJSON(markerSites);

  const legendItems = [];
  const sitesPerformed = isLoading
    ? '-'
    : sitesStatuses.filter(site => site.status === SERVICE_PERFORMED).length;
  const sitesAwaiting = isLoading
    ? '-'
    : sitesStatuses.filter(site => site.status === ACTIVE).length;
  const sitesWithInactiveService = isLoading
    ? '-'
    : sitesStatuses.filter(site => site.status === INACTIVE).length;

  if (isInsideIFrame() || !isInternalUser) {
    legendItems.push({
      id: INACTIVE,
      dataTip: `Sites with no snow events registered at this time`,
      color: colors.neutral85,
      count: sitesWithInactiveService,
      messageDefault: 'Sites',
      messageShort: 'Sites'
    });
    legendItems.push({
      id: SERVICE_PERFORMED,
      dataTip: `Sites with service performed for the current snow event`,
      color: colors.accent100,
      count: sitesPerformed,
      messageDefault: `Sites with Service Performed`
    });
    legendItems.push({
      id: ACTIVE,
      dataTip:
        'Sites currently affected by an active snow event in the region that are awaiting for service.',
      color: colors.neutral0,
      count: sitesAwaiting,
      messageDefault: `Sites Awaiting Service`
    });
  } else {
    const sitesCorrectlyConfirmed = isLoading
      ? '-'
      : sitesStatuses.filter(site => site.status === SERVICE_CONFIRMED).length;
    const sitesWithMissingService = isLoading
      ? '-'
      : sitesStatuses.filter(site => site.status === SERVICE_MISSED).length;

    const servicePerformedDescription = get(
      serviceStatusOptions.find(s => s.value === SERVICE_PERFORMED),
      'description',
      ''
    );
    const serviceConfirmedDescription = get(
      serviceStatusOptions.find(s => s.value === SERVICE_CONFIRMED),
      'description',
      ''
    );
    const serviceMissedDescription = get(
      serviceStatusOptions.find(s => s.value === SERVICE_MISSED),
      'description',
      ''
    );
    const activeDescription = get(
      serviceStatusOptions.find(s => s.value === ACTIVE),
      'description',
      ''
    );
    legendItems.push({
      id: SERVICE_PERFORMED,
      dataTip: servicePerformedDescription,
      color: colors.accent100,
      count: sitesPerformed,
      messageDefault: 'Sites with Service Performed',
      messageShort: 'Service Performed'
    });
    legendItems.push({
      id: SERVICE_CONFIRMED,
      dataTip: serviceConfirmedDescription,
      color: colors.main300,
      count: sitesCorrectlyConfirmed,
      messageDefault: 'Sites with Service Confirmed',
      messageShort: 'Service Confirmed'
    });
    legendItems.push({
      id: ACTIVE,
      dataTip: activeDescription,
      color: colors.neutral0,
      count: sitesAwaiting,
      messageDefault: 'Sites Awaiting Service',
      messageShort: 'Awaiting Service'
    });
    legendItems.push({
      id: SERVICE_MISSED,
      dataTip: serviceMissedDescription,
      color: colors.critical100,
      count: sitesWithMissingService,
      messageDefault: 'Sites with Missing Service',
      messageShort: 'Missing Service'
    });
  }

  return (
    <Media queries={{ statusDescriptions: '(min-width: 1409px)' }}>
      {matches => (
        <Container>
          <Flex
            flexDirection="column"
            p={DEFAULT_CONTAINER_PADDING}
            style={{ boxShadow: '0px 2px 6px #0000001A', rowGap: '16px' }}
          >
            <FilterToggleButton
              isOpen={showFilters}
              appliedFilters={appliedFilters}
              onClick={onToggleFilters}
              onResetFilters={onFilterClear}
            />
            {showFilters && (
              <Flex style={{ columnGap: '16px' }}>
                <Box width="112px">
                  <Select
                    label="Region"
                    placeholder="Select a Region"
                    options={REGION_OPTIONS}
                    value={filter.region}
                    onChange={onFilterChange('region')}
                  />
                </Box>
                <Box width="196px" style={{ display: 'none' }}>
                  <Select
                    label="Customer"
                    placeholder={loadingFilters ? 'Loading Customers...' : 'Select a Customer'}
                    isDisabled={loadingFilters}
                    options={customerOptions}
                    value={filter.customer}
                    onChange={onFilterChange('customer')}
                  />
                </Box>
                <Box width="112px">
                  <Select
                    label="State"
                    placeholder={loadingFilters ? 'Loading States...' : 'Select a State'}
                    isDisabled={loadingFilters}
                    options={stateOptions}
                    value={filter.state}
                    onChange={onFilterChange('state')}
                  />
                </Box>
                <Box width="140px">
                  <Select
                    label="City"
                    placeholder={loadingFilters ? 'Loading Cities...' : 'Select a City'}
                    isDisabled={loadingFilters}
                    options={cityOptions}
                    value={filter.city}
                    onChange={onFilterChange('city')}
                  />
                </Box>
                {!isInsideIFrame() && isInternalUser && (
                  <>
                    <Box width="196px">
                      <Select
                        label="Banner"
                        placeholder={loadingFilters ? 'Loading Banners...' : 'Select a Banner'}
                        isDisabled={loadingFilters}
                        options={bannerOptions}
                        value={filter.banner}
                        onChange={onFilterChange('banner')}
                      />
                    </Box>
                    <Box width="196px">
                      <Select
                        label="Hierarchy"
                        placeholder={
                          loadingFilters ? 'Loading Hierarchies...' : 'Select a Hierarchy'
                        }
                        isDisabled={loadingFilters}
                        options={hierarchyOptions}
                        value={filter.hierarchy}
                        onChange={onFilterChange('hierarchy')}
                      />
                    </Box>
                    <Box width="196px">
                      <Select
                        label="Subcontractor"
                        placeholder={
                          loadingFilters ? 'Loading Subcontractors...' : 'Select a Subcontractor'
                        }
                        isDisabled={loadingFilters}
                        options={subcontractorOptions}
                        value={filter.subcontractor}
                        onChange={onFilterChange('subcontractor')}
                      />
                    </Box>
                  </>
                )}
                <Box width="168px">
                  <Select
                    label="Service status"
                    placeholder={
                      loadingStatusDescriptions
                        ? 'Loading Service Statuses...'
                        : 'Select a Service Status'
                    }
                    isDisabled={loadingStatusDescriptions}
                    options={serviceStatusOptions}
                    value={filter.serviceStatus}
                    onChange={onFilterChange('serviceStatus')}
                  />
                </Box>
                <Box width="196px">
                  <Select
                    label="Site"
                    placeholder={loadingFilters ? 'Loading Sites...' : 'Select a Site'}
                    isDisabled={loadingFilters}
                    options={siteOptions}
                    value={filter.site}
                    onChange={onFilterChange('site')}
                  />
                </Box>
              </Flex>
            )}
          </Flex>

          <Flex width="100%" height="100%" style={{ position: 'relative' }}>
            <Box
              width="100%"
              height="100%"
              style={{ position: 'relative', top: '0px', left: '0px' }}
            >
              <Mapbox
                id="map"
                accessToken={mbApiKey}
                aerisClientId={aerisClientId}
                aerisClientSecret={aerisClientSecret}
                markers={markers}
                loadingMarkers={isLoading}
                onMarkerClick={onMarkerClick}
                autozoom={shouldShowLoading}
                siteWeatherConditions={siteWeatherConditions}
                onGetSiteConditions={onGetSiteConditions}
                currentOffset={currentOffset}
                onCurrentOffsetChange={onCurrentOffsetChange}
                playingAnimation={playingAnimation}
                region={filter.region.value}
                onFinishedLoad={setIsMapLoaded}
              />
            </Box>
            <MapOverlayPanel
              width={selectedSite ? '65%' : '100%'}
              height={TOP_OVERLAY_HEIGHT}
              style={{ position: 'absolute', top: '0px', left: '0px', columnGap: '32px' }}
              p="8px"
              justifyContent="center"
              alignItems="center"
            >
              {legendItems.map(item => (
                <Flex
                  alignItems="center"
                  data-tooltip-content={item.dataTip}
                  key={`f${item.id}`}
                  gridColumnGap="6px"
                >
                  <SnowMapSingleLocationIcon small color={item.color} />
                  <TextS variant="text_S_low-line-spacing_Neutral-0">
                    {selectedSite && matches.statusDescriptions
                      ? item.messageShort || item.messageDefault
                      : item.messageDefault}
                    : {item.count}
                  </TextS>
                </Flex>
              ))}
            </MapOverlayPanel>
            <Box
              style={{
                position: 'absolute',
                top: TOP_OVERLAY_HEIGHT + DEFAULT_CONTAINER_PADDING,
                right: selectedSite
                  ? `calc(35% + ${DEFAULT_CONTAINER_PADDING}px)`
                  : DEFAULT_CONTAINER_PADDING
              }}
              data-tooltip-content={
                loadingFilters || loadingSitesStatus ? 'Refreshing...' : 'Refresh map info'
              }
              data-tooltip-place={LEFT}
            >
              <MapOverlayButton
                disabled={loadingFilters || loadingSitesStatus}
                animate={loadingFilters || loadingSitesStatus}
                onClick={onFilterRefresh}
              >
                <RefreshIcon color="white" />
              </MapOverlayButton>
            </Box>
            {location.pathname !== '/' && (
              <MapOverlayPanel
                width={selectedSite ? '65%' : '100%'}
                height={BOTTOM_OVERLAY_HEIGHT}
                p="24px"
                justifyContent="space-between"
                alignItems="flex-start"
                style={{ position: 'absolute', bottom: '0px', left: '0px' }}
              >
                <Flex height="38px" alignItems="center" gridColumnGap="20px">
                  <Flex alignItems="center" gridColumnGap="12px">
                    <TextM variant="text_M_medium_Neutral-0">
                      {[SNOW_PATH, SNOW_ACCUMULATION_PATH].includes(location.pathname) &&
                        'Forecast'}
                      {[ESTIMATED_SNOW_DEPTH_PATH, TEMPERATURE_PATH].includes(location.pathname) &&
                        'Observation'}
                    </TextM>
                    {PATHS_WITH_AERIS_LAYERS.includes(location.pathname) && (
                      <TextM variant="text_M_Neutral-0">
                        {location.pathname === SNOW_PATH
                          ? '(1 Hour Intervals)'
                          : `Today, ${moment().format(DATE_FORMAT)}`}
                      </TextM>
                    )}
                  </Flex>
                  {location.pathname === SNOW_PATH && (
                    <AnimationPlayer
                      currentOffset={currentOffset}
                      isPlaying={playingAnimation}
                      onPlayPause={onTogglePlaying}
                      small={selectedSite}
                    />
                  )}
                </Flex>
                <MapLegend type={location.pathname} />
              </MapOverlayPanel>
            )}
            <Flex
              width={selectedSite ? '35%' : '0%'}
              height="100%"
              style={{
                position: 'absolute',
                top: '0px',
                right: '0px',
                transition: 'all 0.2s ease-out',
                backgroundColor: colors.neutral0,
                backdropFilter: 'blur(20px)'
              }}
            >
              {selectedSite && (
                <SiteDetails
                  key={selectedSite.id}
                  siteId={selectedSite.id}
                  siteStatus={selectedSite.status}
                  onClose={onCloseDetails}
                />
              )}
            </Flex>
          </Flex>
        </Container>
      )}
    </Media>
  );
};

export default MapExplorer;
