import { get, set } from 'lodash';
import * as api from '../../api/endpoints';
import { isInsideIFrame, parseUrlParams } from '../../utils';
import {
  ALASKA,
  ONE,
  ESTIMATED_SNOW_DEPTH_PATH,
  USA,
  SNOW_PATH,
  INTERNAL_ROLE
} from '../../utils/constants';
import { ANALYTICS_EVENTS } from '../../utils/analytics';

export const FILTERS_REQUEST = 'snowmap/mapExplorer/FILTERS_REQUEST';
export const filtersRequest = () => ({
  type: FILTERS_REQUEST
});

export const FILTERS_RESPONSE = 'snowmap/mapExplorer/FILTERS_RESPONSE';
export const filtersResponse = response => ({
  type: FILTERS_RESPONSE,
  response
});

export const FILTERS_ERROR = 'snowmap/mapExplorer/FILTERS_ERROR';
export const filtersError = error => ({
  type: FILTERS_ERROR,
  error
});

export const getFilters = () => async (dispatch, getState) => {
  try {
    dispatch(filtersRequest());
    const filter = getState().mapExplorer.filter;
    const legacyCustomerId = getState().mapExplorer.customerId;
    const res = await api.getFilters({
      ...(isInsideIFrame() && { isInsideIFrame: true }),
      ...(legacyCustomerId && { legacyCustomerId }),
      ...(filter.region.value === ALASKA && { region: USA }),
      ...(filter.region.value !== ALASKA && { region: filter.region.value }),
      ...(filter.city.value !== -ONE && { city: filter.city.value }),
      ...(filter.state.value !== -ONE && { state: filter.state.value }),
      ...(filter.customer.value !== -ONE && { customerId: filter.customer.value }),
      ...(filter.banner.value !== -ONE && { bannerId: filter.banner.value }),
      ...(filter.hierarchy.value !== -ONE && { managerId: filter.hierarchy.value }),
      ...(filter.subcontractor.value !== -ONE && { vendorId: filter.subcontractor.value })
    });
    set(res, 'skipNotification', true);
    dispatch(filtersResponse(res));
  } catch (err) {
    dispatch(filtersError(err));
  }
};

export const SITES_STATUS_REQUEST = 'snowmap/mapExplorer/SITES_STATUS_REQUEST';
export const sitesStatusRequest = () => ({
  type: SITES_STATUS_REQUEST
});

export const SITES_STATUS_RESPONSE = 'snowmap/mapExplorer/SITES_STATUS_RESPONSE';
export const sitesStatusResponse = response => ({
  type: SITES_STATUS_RESPONSE,
  response
});

export const SITES_STATUS_ERROR = 'snowmap/mapExplorer/SITES_STATUS_ERROR';
export const sitesStatusError = error => ({
  type: SITES_STATUS_ERROR,
  error
});

export const getSitesStatus = () => async (dispatch, getState) => {
  try {
    dispatch(sitesStatusRequest());
    const filter = getState().mapExplorer.filter;
    const legacyCustomerId = getState().mapExplorer.customerId;
    const res = await api.getSitesStatus({
      ...(isInsideIFrame() && { isInsideIFrame: true }),
      ...(legacyCustomerId && { legacyCustomerId }),
      ...(filter.region.value !== -ONE && { region: filter.region.value }),
      ...(filter.state.value !== -ONE && { state: filter.state.value }),
      ...(filter.city.value !== -ONE && { city: filter.city.value }),
      ...(filter.serviceStatus.value !== -ONE && { status: filter.serviceStatus.value }),
      ...(filter.site.value !== -ONE && { siteId: filter.site.value }),
      ...(filter.banner.value !== -ONE && { bannerId: filter.banner.value }),
      ...(filter.hierarchy.value !== -ONE && { managerId: filter.hierarchy.value }),
      ...(filter.subcontractor.value !== -ONE && { vendorId: filter.subcontractor.value })
      // ...(filter.customer.value !== -ONE && { customerId: filter.customer.value })
    });
    set(res, 'skipNotification', true);
    dispatch(sitesStatusResponse(res));
  } catch (err) {
    dispatch(sitesStatusError(err));
  }
};

export const STATUS_DESCRIPTIONS_REQUEST = 'snowmap/mapExplorer/STATUS_DESCRIPTIONS_REQUEST';
export const statusDescriptionsRequest = () => ({
  type: STATUS_DESCRIPTIONS_REQUEST
});

export const STATUS_DESCRIPTIONS_RESPONSE = 'snowmap/mapExplorer/STATUS_DESCRIPTIONS_RESPONSE';
export const statusDescriptionsResponse = response => ({
  type: STATUS_DESCRIPTIONS_RESPONSE,
  response
});

export const STATUS_DESCRIPTIONS_ERROR = 'snowmap/mapExplorer/STATUS_DESCRIPTIONS_ERROR';
export const statusDescriptionsError = error => ({
  type: STATUS_DESCRIPTIONS_ERROR,
  error
});

export const getStatusDescriptions = () => async dispatch => {
  try {
    dispatch(statusDescriptionsRequest());
    const res = await api.getStatusDescriptions({
      ...(isInsideIFrame() && { isInsideIFrame: true })
    });
    set(res, 'skipNotification', true);
    dispatch(statusDescriptionsResponse(res));
  } catch (err) {
    dispatch(statusDescriptionsError(err));
  }
};

export const SITE_WEATHER_CONDITIONS_REQUEST =
  'snowmap/mapExplorer/SITE_WEATHER_CONDITIONS_REQUEST';
export const siteWeatherConditionsRequest = () => ({
  type: SITE_WEATHER_CONDITIONS_REQUEST
});

export const SITE_WEATHER_CONDITIONS_RESPONSE =
  'snowmap/mapExplorer/SITE_WEATHER_CONDITIONS_RESPONSE';
export const siteWeatherConditionsResponse = (id, response, weather) => ({
  type: SITE_WEATHER_CONDITIONS_RESPONSE,
  id,
  response,
  weather
});

export const SITE_WEATHER_CONDITIONS_ERROR = 'snowmap/mapExplorer/SITE_WEATHER_CONDITIONS_ERROR';
export const siteWeatherConditionsError = error => ({
  type: SITE_WEATHER_CONDITIONS_ERROR,
  error
});

export const getSiteWeatherConditions = (site, weather) => async (dispatch, getState) => {
  try {
    dispatch(siteWeatherConditionsRequest());
    const { aerisClientId, aerisClientSecret } = getState().app;
    const { id, lat, long } = site;
    const res = [SNOW_PATH, ESTIMATED_SNOW_DEPTH_PATH].includes(weather)
      ? await api.getAerisObservations(lat, long, {
          client_id: aerisClientId,
          client_secret: aerisClientSecret
        })
      : await api.getAerisConditions(lat, long, {
          format: 'json',
          filter: '1min',
          plimit: '1',
          client_id: aerisClientId,
          client_secret: aerisClientSecret
        });
    set(res, 'skipNotification', true);
    dispatch(siteWeatherConditionsResponse(id, res, weather));
  } catch (err) {
    dispatch(siteWeatherConditionsError(err));
  }
};

export const filterReload = () => async dispatch => {
  await dispatch(getFilters());
  await dispatch(getSitesStatus());
};

export const FILTER_CHANGE = 'snowmap/mapExplorer/FILTER_CHANGE';
export const filterChange = (key, value) => ({
  type: FILTER_CHANGE,
  key,
  value
});

export const changeFilter = (key, value) => async dispatch => {
  await dispatch(filterChange(key, value));
  window.gtag?.('event', ANALYTICS_EVENTS.filtersChange, {
    filterKey: key,
    filterValue: value.label
  });
  if (key === 'region' && value.value === ALASKA)
    await dispatch(filterChange('state', { value: ALASKA, label: ALASKA }));
  if (key !== 'site') {
    await dispatch(getFilters());
    await dispatch(getSitesStatus());
  }
};

export const FILTER_CLEAR = 'snowmap/mapExplorer/FILTER_CLEAR';
export const filterClear = () => ({
  type: FILTER_CLEAR
});

export const clearFilter = () => async dispatch => {
  await dispatch(filterClear());
  window.gtag?.('event', ANALYTICS_EVENTS.filtersReset);
  await dispatch(getFilters());
  await dispatch(getSitesStatus());
};

export const SET_CUSTOMER = 'snowmap/mapExplorer/SET_CUSTOMER';
export const setCustomer = customerId => ({ type: SET_CUSTOMER, customerId });

export const componentMount = () => async (dispatch, getState) => {
  const isInternalUser = getState().app.user.roles.includes(INTERNAL_ROLE);
  if (isInternalUser) {
    const location = window.location.href;
    const params = parseUrlParams(location);
    if (get(params, 'customerId')) {
      dispatch(setCustomer(params.customerId));
    }
  } else {
    dispatch(setCustomer(getState().app.user.customerNumber));
  }
  await Promise.all([dispatch(getFilters()), dispatch(getStatusDescriptions())]);
  await dispatch(getSitesStatus());
};
