import * as HttpStatusCodes from 'http-status-codes';
import { get } from 'lodash';
import { toast } from '@kbs/kbsforce-components';

const ERROR_SUFFIX = 'ERROR';
const DEFAULT_ERROR_MESSAGE = 'Unknown error';

const authMiddleware = () => next => action => {
  const isError = action.type && action.type.match(new RegExp(`${ERROR_SUFFIX}$`, 'g'));
  if (isError && action.error && action.error.status === HttpStatusCodes.UNAUTHORIZED) {
    if (action.error.body && action.error.body.redirect) {
      window.location = action.error.body.redirect;
    }
  }
  if (action.response && action.response.redirect) {
    window.location = action.response.redirect;
  }
  next(action);
};

const notificationsMiddleware = () => next => action => {
  const isError = action.type && action.type.match(new RegExp(`${ERROR_SUFFIX}$`, 'g'));
  if (isError && action.error && !action.error.skipNotification) {
    const notification = action?.error?.title || 'Error';
    const message =
      get(action.error, 'message') ||
      get(action.error, 'body.message') ||
      get(action.error, 'body.error.error.code') ||
      get(action.error, 'statusText') ||
      DEFAULT_ERROR_MESSAGE;

    toast.error({ title: notification, message });
  }
  next(action);
};

export { authMiddleware, notificationsMiddleware };
