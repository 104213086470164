import { get } from 'lodash';
import {
  COMPONENT_UNMOUNT,
  CURRENT_CONDITIONS_ERROR,
  CURRENT_CONDITIONS_REQUEST,
  CURRENT_CONDITIONS_RESPONSE,
  SITE_ERROR,
  SITE_REQUEST,
  SITE_RESPONSE
} from './actions';

const defaultState = {
  loadingSite: false,
  loadingCurrentConditions: false,
  loadingPast3DaysConditions: false,
  error: null,
  site: null,
  currentConditions: null,
  next3DaysConditions: [{}, {}, {}]
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case SITE_REQUEST:
      return {
        ...state,
        loadingSite: true,
        site: null,
        error: null
      };
    case SITE_RESPONSE:
      return {
        ...state,
        loadingSite: false,
        site: action.response
      };
    case SITE_ERROR:
      return {
        ...state,
        loadingSite: false,
        error: action.error
      };
    case CURRENT_CONDITIONS_REQUEST:
      return {
        ...state,
        loadingCurrentConditions: true,
        error: null,
        currentConditions: defaultState.currentConditions
      };
    case CURRENT_CONDITIONS_RESPONSE: {
      const conditions = action.response.conditionsRes.response[0].periods[0];
      const phrases = action.response.phrasesRes.response[0].phrases;
      const forecasts = action.response.forecastsRes.response[0].periods[0];
      const observations = action.response.observationsRes.response.ob;
      const next3DaysForecasts = action.response.forecastsRes.response[0].periods.slice(1);
      return {
        ...state,
        loadingCurrentConditions: false,
        currentConditions: {
          dateTimeISO: get(conditions, 'dateTimeISO') || get(observations, 'dateTimeISO'),
          tempF: get(conditions, 'tempF'),
          humidity: get(conditions, 'humidity'),
          weather: get(conditions, 'weather'),
          weatherPrimaryCoded: get(conditions, 'weatherPrimaryCoded'),
          icon: get(conditions, 'icon'),
          isDay: get(conditions, 'isDay'),
          sky: get(conditions, 'sky'),
          snowDepthIN: get(observations, 'snowDepthIN'),
          snowIN: get(conditions, 'snowIN'),
          // TODO: see what to do with snowIN forecast
          // snowIN: get(forecasts, 'snowIN'),
          phrase: get(phrases, 'short'),
          maxTempF: get(forecasts, 'maxTempF'),
          minTempF: get(forecasts, 'minTempF')
        },
        next3DaysConditions: next3DaysForecasts.map(forecast => ({
          dateTimeISO: get(forecast, 'dateTimeISO'),
          maxTempF: get(forecast, 'maxTempF'),
          minTempF: get(forecast, 'minTempF'),
          weatherPrimaryCoded: get(forecast, 'weatherPrimaryCoded'),
          icon: get(forecast, 'icon'),
          snowDepthIN: get(forecast, 'snowDepthIN'),
          snowIN: get(forecast, 'snowIN')
        }))
      };
    }
    case CURRENT_CONDITIONS_ERROR:
      return {
        ...state,
        loadingCurrentConditions: false,
        error: action.error
      };
    case COMPONENT_UNMOUNT:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
