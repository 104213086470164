import React from 'react';
import { EmailIcon, Flex, Link, PhoneIcon, TextM } from '@kbs/kbsforce-components';
import { useTheme } from 'styled-components';

const ManagerContactInfo = props => {
  const { colors } = useTheme();

  return (
    <Flex mt="12px" gridColumnGap="16px">
      <Flex alignItems="center" gridColumnGap="6px">
        <PhoneIcon small color={colors.neutral85} />
        <TextM variant="text_M">
          {props?.phoneNumber ? (
            <Link href={`tel:${props.phoneNumber}`}>{props.phoneNumber}</Link>
          ) : (
            '-'
          )}
        </TextM>
      </Flex>
      <Flex alignItems="center" gridColumnGap="6px">
        <EmailIcon small color={colors.neutral85} />
        <TextM variant="text_M">
          {props?.email ? (
            <Link href={`mailto:${props.email}?subject=${props.emailSubject}`}>{props.email}</Link>
          ) : (
            '-'
          )}
        </TextM>
      </Flex>
    </Flex>
  );
};

export default ManagerContactInfo;
