import { startCase } from 'lodash';

/** Event names used in Google Analytics 4. */
export const ANALYTICS_EVENTS = {
  filtersChange: 'fitlersChange',
  filtersReset: 'filtersReset',
  pageView: 'page_view',
  siteDetailsOpen: 'siteDetailsOpen'
};

/**
 * Gets parameters for using in a page_view event.
 * @param {String} path the current path
 * @param {String} title the page title
 * @returns an object with parameters
 * @see https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
 */
export const getPageViewParams = path => ({
  // eslint-disable-next-line camelcase
  page_location: path,
  language: 'en_us',
  // eslint-disable-next-line camelcase
  page_encoding: 'UTF-8',
  // eslint-disable-next-line camelcase
  page_title: path === '/' ? 'Sites Only' : startCase(path),
  // eslint-disable-next-line camelcase
  user_agent: window.navigator.userAgent
});
