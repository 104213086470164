import { combineReducers } from 'redux';
import app from './containers/App/reducer';
import mapExplorer from './containers/MapExplorer/reducer';
import siteDetails from './containers/SiteDetails/reducer';

const appReducers = combineReducers({
  app,
  mapExplorer,
  siteDetails
});

export default appReducers;
